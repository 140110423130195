import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";
import * as actions from "../../store/actions";

import Validators from "../../shared/Validations";
import Logo from "../../assets/img/brand/login_logo.png";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      name: "",
      nameTouched: false,
      nameOkay: false,
      emailTouched: false,
      emailOkay: false,
      passwordTouched: false,
      passwordOkay: false,
      confirmPasswordTouched: false,
      confirmPasswordOkay: false,
      enableSubmit: false,
    };
    this.validators = {
      name: {
        validators: value => Validators.fieldRequired(value, 4),
        errorMessage: "Name cannot be less than 5 characters"
      },
      email: {
        validators: value => Validators.email(value),
        errorMessage: "Invalid Email"
      },
      password: {
        validators: value => Validators.fieldRequired(value, 7),
        errorMessage: "Password cannot be less than 8 characters"
      },
      confirmPassword: {
        validators: value => Validators.fieldMatch(this.state.password, value),
        errorMessage: "Passwords don't match"
      }
    };
  }

  submitHandler = event => {
    event.preventDefault();
    this.props.onSignUp(this.state.name, this.state.email, this.state.password);
  };
  inputChangeHandler = (value, controlName, checkConfirmPassword = false) => {
    this.setState(
      {
        [controlName]: value,
        [`${controlName}Touched`]: true,
        [`${controlName}Okay`]: this.validators[controlName].validators(
          value
        )
      },
      () => {
        if (this.state.confirmPassword && checkConfirmPassword)
          this.inputChangeHandler(this.state.confirmPassword, "confirmPassword");
        this.setState({ enableSubmit: this.handleEnableSubmit() })
      }
    );
  };

  handleEnableSubmit = () => {
    return (
      this.state.nameTouched &&
      this.state.nameOkay &&
      this.state.emailTouched &&
      this.state.emailOkay &&
      this.state.passwordTouched &&
      this.state.passwordOkay &&
      this.state.confirmPasswordTouched &&
      this.state.confirmPasswordOkay
    );
  };

  componentDidMount() {
    this.props.onTryAutoSignIn();
  }
  // reactstrap components
  render() {
    let authRedirect = null;
    let { isAuthenticated, authRedirectPath } = this.props;
    if (!isAuthenticated && authRedirectPath !== "/auth/register") {
      authRedirect = <Redirect push to={this.props.authRedirectPath} />;

      return authRedirect;
    }
    return (
      <>
        <Container className="mt--6 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary shadow rounded-10">
                <CardHeader className="px-lg-7 py-lg-6">
                  <div className="text-center text-muted mb-4">
                    <img src={Logo} alt="logo" width="100%" />
                  </div>
                  <Form role="form">
                    <FormGroup>
                      <InputGroup className="input-group-alternative mb-3 border-style">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-hat-3" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          autoComplete="off"
                          placeholder="Name"
                          type="text"
                          onChange={event =>
                            this.inputChangeHandler(event.target.value, "name")
                          }
                        />
                      </InputGroup>
                      {this.state.nameTouched &&
                        !this.state.nameOkay ? (
                          <span className="text-danger ">
                            <small>
                              {this.validators["name"].errorMessage}
                            </small>
                          </span>
                        ) : null}
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative mb-3 border-style">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          autoComplete="off"
                          placeholder="Email"
                          type="email"
                          onChange={event =>
                            this.inputChangeHandler(event.target.value, "email")
                          }
                        />
                      </InputGroup>
                      {this.state.emailTouched && !this.state.emailOkay ? (
                        <span className="text-danger">
                          <small>{this.validators["email"].errorMessage}</small>
                        </span>
                      ) : null}
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative border-style">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          autoComplete="off"
                          placeholder="Password"
                          type="password"
                          onChange={event => this.inputChangeHandler(event.target.value, "password", true)
                          }
                        />

                      </InputGroup>
                      {this.state.passwordTouched &&
                        !this.state.passwordOkay ? (
                          <span className="text-danger ">
                            <small>
                              {this.validators["password"].errorMessage}
                            </small>
                          </span>
                        ) : null}
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative border-style">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          autoComplete="off"
                          placeholder="Confirm Password"
                          type="password"
                          onChange={event => this.inputChangeHandler(event.target.value, "confirmPassword")
                          }
                        />

                      </InputGroup>
                      {this.state.confirmPasswordTouched &&
                        !this.state.confirmPasswordOkay ? (
                          <span className="text-danger ">
                            <small>
                              {this.validators["confirmPassword"].errorMessage}
                            </small>
                          </span>
                        ) : null}
                    </FormGroup>
                    {/* <div className="text-muted font-italic">
                      <small>
                        password strength:{" "}
                        <span className="text-success font-weight-700">
                          strong
                        </span>
                      </small>
                    </div> */}
                    <Row className="my-4">
                      {/* <Col xs="12">
                        <div className="custom-control custom-control-alternative custom-checkbox">
                          <input
                            className="custom-control-input"
                            id="customCheckRegister"
                            type="checkbox"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheckRegister"
                          >
                            <span className="text-muted">
                              I agree with the{" "}
                              <a
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                Privacy Policy
                              </a>
                            </span>
                          </label>
                        </div>
                      </Col> */}
                    </Row>
                    <div className="text-center">
                      <Button
                        className="mt-4 btn-block btn-border-style"
                        color="primary"
                        type="button"
                        onClick={this.submitHandler}
                        disabled={!this.state.enableSubmit}
                      >
                        Create account
                      </Button>
                    </div>
                  </Form>
                </CardHeader>
              </Card>
              <Row className="mt-3">
                <Col xs="6">
                  {/* <a
                className="text-light"
                href="#test"
                onClick={e => e.preventDefault()}
              >
                <small>Forgot password?</small>
              </a> */}
                </Col>
                <Col className="text-right" xs="6">
                  <a
                    // className="text-light"
                    href="/auth/login"
                  // onClick={e => e.preventDefault()}
                  >
                    <small>Already have an account?</small>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    msg: state.auth.msg,
    isAuthenticated: state.auth.user !== null,
    authRedirectPath: state.auth.authRedirectPath,
    signup: state.auth.signup
  };
};

const mapDispatchToProps = dispatch => ({
  //onSignIn: (Username, Password) => dispatch(actions.signIn(Username, Password)),
  onSignUp: (name, email, password) =>
    dispatch(actions.signUp(name, email, password)),
  onTryAutoSignIn: () => dispatch(actions.checkAuthState())
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
