import React from 'react'
import oritain_Certificate from '../assets/img/icons/oritain _certificate_logo.png';
import sgs_Certificate from '../assets/img/icons/sgs_certificate.png';
import origin_Certificate from '../assets/img/icons/origin_certificate.png';
import catgo_Certificate from '../assets/img/icons/catgo_certificate.png';

const certificateImageData = (certificateName) => {
    switch (certificateName) {
        case 'SGS':
            return {
                imageName: sgs_Certificate,
                description: <p>The Certification represents third party lab verification of claimed origin of raw material (cotton)by fingerprint metrics analysis.<br></br><br></br>We have collaborated with Oritain for independent, scientific sampling and analysis of raw material for origin verification</p>
            }
        case 'ORITAIN':
            return {
                imageName: oritain_Certificate,
                description: <p>Oritain Certification demonstrates a commitment to high standards of quality and safety. It is a visible sign that shows the power of science is being deployed to help protect the integrity of a product. <br></br><br></br>This is a powerful tool that links the origin claim on the packaging to the actual product inside.</p>
            }
        case 'ORIGIN':
            return {
                imageName: origin_Certificate,
                description: <p>A Certificate of Origin (CO)is an important international trade document that certifies that goods in a particular  export shipment are wholly obtained,produced,manufactured or processed in a particular Country.They declare the 'nationality' of the product and also serve as a declaration by the exporter to satisfy customs or trade requirements.</p>
            }
        case 'CATGO':
            return {
                imageName: catgo_Certificate,
                description: <p>-Maintaining the purity of the Egyptain cotton varities from all forms of mixing and contamination.<br></br><br></br>-Supervising Egyptain cotton quality in all handling stages starting from harvesting till its delivery to local spinning mills or for exporting.</p>
            }

        default:
            break;
    }
}

export default certificateImageData;
