import * as actionTypes from '../actions/action-types';
import { updateObject, stringEncoder } from '../../shared/utility';

const initialState = {
  success: null,
  loading: false,
  errors: null,
  msg: null,
  batchApprovalSuccess: null,
  batchApprovalLoading: false,
  batchApprovalErrors: null,
  batchApprovalMsg: null,
  exportReportSuccess: null,
  exportReportLoading: false,
  exportReportErrors: null,
  exportReportMsg: null,
  report: {},
  allDepartments: [],
  departmentById: {},
  breadCrumbItems: [],
  batchById: {},
  batchApprovalStatus: {}
};

const initStart = (state, action) =>
  updateObject(state, {
    errors: null,
    loading: true,
    success: null,
    msg: null,
    allDepartments: []
  });

const initSuccess = (state, action) =>
  updateObject(state, {
    errors: null,
    loading: false,
    success: true,
    msg: null,
    allDepartments: action.departments
  });

const initFail = (state, action) => {
  return updateObject(state, {
    errors: true,
    msg: action.error.message,
    loading: false,
    success: false
  });
};
const getDepartmentByIdStart = (state, action) => {
  return updateObject(state, {
    errors: null,
    loading: true,
    success: null,
    msg: null,
    departmentById: {}
  });
};
const getDepartmentByIdSuccess = (state, action) => {
  return updateObject(state, {
    errors: null,
    loading: false,
    success: true,
    msg: null,
    departmentById: action.departmentById
  });
};

const getDepartmentByIdFail = (state, action) => {
  return updateObject(state, {
    errors: true,
    msg: action.error.message,
    loading: false,
    success: false,
    departmentById: {}
  });
};

const exportBatchListFileStart = (state, action) => {
  return updateObject(state, {
    exportReportErrors: null,
    exportReportLoading: true,
    exportReportSuccess: null,
    exportReportMsg: null,
    report: {}
  });
};
const exportBatchListFileSuccess = (state, action) => {
  return updateObject(state, {
    exportReportErrors: null,
    exportReportLoading: false,
    exportReportSuccess: true,
    exportReportMsg: null,
    report: action.report
  });
};

const exportBatchListFileFail = (state, action) => {
  return updateObject(state, {
    exportReportErrors: true,
    exportReportMsg: action.error.message,
    exportReportLoading: false,
    exportReportSuccess: false,
    report: {}
  });
};

const getBatchByIdStart = (state, action) => {
  return updateObject(state, {
    errors: null,
    loading: true,
    success: null,
    msg: null,
    batchById: {}
  });
};
const getBatchByIdSuccess = (state, action) => {
  let { batchById } = action;
  let updatedBatchById = {};
  if (state.batchById['activities']) {
    let newActivity = {
      description: batchById.activity.description,
      documents: batchById.documents,
      name: batchById.activity.name,
      type: batchById.activity.type,
      updated_at: batchById.activity.updated_at,
      is_visible: batchById.activity.is_visible,
      inserted_at: batchById.activity.inserted_at,
      id: batchById.activity.id
    };
    updatedBatchById = {};
    Object.assign(updatedBatchById, state.batchById);
    updatedBatchById.activities.push(newActivity);
  } else {
    updatedBatchById = {};
    Object.assign(updatedBatchById, action.batchById);
  }
  return updateObject(state, {
    batchApprovalStatus: null,
    batchApprovalErrors: null,
    batchApprovalLoading: false,
    errors: null,
    loading: false,
    success: true,
    msg: null,
    batchById: updatedBatchById
  });
};

const getBatchByIdFail = (state, action) => {
  return updateObject(state, {
    errors: true,
    msg: action.error.message,
    loading: false,
    success: false,
    batchById: {}
  });
};

const approveBatchByNumberStart = (state, action) => {
  return updateObject(state, {
    batchApprovalErrors: null,
    batchApprovalLoading: true,
    batchApprovalSuccess: null,
    batchApprovalMsg: null,
    batchApprovalStatus: {}
  });
};
const approveBatchByNumberSuccess = (state, action) => {
  return updateObject(state, {
    batchApprovalErrors: null,
    batchApprovalLoading: false,
    batchApprovalSuccess: true,
    batchApprovalMsg: null,
    batchApprovalStatus: action.batchApprovalStatus
  });
};

const approveBatchByNumberFail = (state, action) => {
  return updateObject(state, {
    batchApprovalErrors: true,
    batchApprovalMsg: action.error.msg,
    batchApprovalLoading: false,
    batchApprovalSuccess: false,
    batchApprovalStatus: {}
  });
};

const resetMessages = (state, action) => {
  return updateObject(state, {
    msg: null,
    errors: null,
    success: null,
    loading: false
  });
};

const breadCrumb = (state, action) => {
  let { deptName, batchName, deptId, batchNumber, batchId } = action;
  let url = '';
  if (action.deptName === 'Departments') {
    return updateObject(state, {
      breadCrumbItems: []
    });
  } else if (action.batchName === 'Batch' && state.breadCrumbItems.length) {
    url = `/admin/departments/${action.deptId}/batches/${stringEncoder(action.batchNumber)}`;
    const item = { deptName, batchName, batchNumber, deptId, batchId, url };
    let stateBreadCrumbItems = [...state.breadCrumbItems];
    if (
      state.breadCrumbItems.filter(item => item.batchNumber === action.batchNumber)
        .length
    ) {
      stateBreadCrumbItems.map((item, index) => {
        if (
          item.batchName === 'Batch' &&
          item.batchNumber === action.batchNumber
        ) {
          stateBreadCrumbItems.splice(
            index + 1,
            stateBreadCrumbItems.length - 1
          );
        }
        return null;
      });
      return updateObject(state, {
        breadCrumbItems: stateBreadCrumbItems
      });
    } else {
      return updateObject(state, {
        breadCrumbItems: [...state.breadCrumbItems, item]
      });
    }
  } else {
    url = '/admin/departments/index';
    const rootItem = {
      deptName: 'Departments',
      batchName: 'none',
      batchNumber: 0,
      deptId: deptId,
      batchId: 0,
      url
    };
    const selectedDept = {
      deptName,
      batchName: 'targetDept',
      deptId,
      batchId: 0,
      batchNumber: 0,
      url: `/admin/departments/${action.deptId}`
    };
    const selectedBatch = {
      deptName,
      batchName: 'targetBatch',
      deptId,
      batchId: action.batchId,
      batchNumber: action.batchNumber,
      url: `/admin/departments/${action.deptId}/batches/${stringEncoder(action.batchNumber)}`
    };
    let finalArray = [rootItem, selectedDept];
    if (batchNumber) finalArray.push(selectedBatch);
    return updateObject(state, {
      breadCrumbItems: finalArray
    });
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INIT_START:
      return initStart(state, action);
    case actionTypes.INIT_SUCCESS:
      return initSuccess(state, action);
    case actionTypes.INIT_FAIL:
      return initFail(state, action);

    case actionTypes.GET_DEPARTMENT_BY_ID_START:
      return getDepartmentByIdStart(state, action);
    case actionTypes.GET_DEPARTMENT_BY_ID_SUCCESS:
      return getDepartmentByIdSuccess(state, action);
    case actionTypes.GET_DEPARTMENT_BY_ID_FAIL:
      return getDepartmentByIdFail(state, action);

    case actionTypes.GET_BATCH_BY_ID_START:
      return getBatchByIdStart(state, action);
    case actionTypes.GET_BATCH_BY_ID_SUCCESS:
      return getBatchByIdSuccess(state, action);
    case actionTypes.GET_BATCH_BY_ID_FAIL:
      return getBatchByIdFail(state, action);

    case actionTypes.EXPORT_BATCH_LIST_FILE_START:
      return exportBatchListFileStart(state, action);
    case actionTypes.EXPORT_BATCH_LIST_FILE_SUCCESS:
      return exportBatchListFileSuccess(state, action);
    case actionTypes.EXPORT_BATCH_LIST_FILE_FAIL:
      return exportBatchListFileFail(state, action);

    case actionTypes.APPROVE_BATCH_BY_NUMBER_START:
      return approveBatchByNumberStart(state, action);
    case actionTypes.APPROVE_BATCH_BY_NUMBER_SUCCESS:
      return approveBatchByNumberSuccess(state, action);
    case actionTypes.APPROVE_BATCH_BY_NUMBER_FAIL:
      return approveBatchByNumberFail(state, action);

    case actionTypes.RESET_MESSAGES:
      return resetMessages(state, action);
    case actionTypes.BREADCRUMB:
      return breadCrumb(state, action);

    default:
      return state;
  }
};

export default reducer;
