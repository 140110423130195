import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from 'reactstrap';
import Logo from '../../assets/img/brand/login_logo.png';
import { simpleErrorMsg, titleCaseConverter } from '../../shared/utility';
import Validators from '../../shared/Validations';

import { forgotPassword, resetAlerts } from '../../store/actions/index';

export default function ForgotPassword(props) {

  const dispatch = useDispatch();

  useEffect(()=>{dispatch(resetAlerts(0))},[])
  const [emailProperties, setEmailProperties] = useState({
    email: '',
    emailClicked: false,
    emailTouched: false,
    emailOkay: false
  });
  const [redirectNow, setRedirectNow] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [updating, setUpdating] = useState(false);
  const validators = {
    email: {
      validators: value => Validators.email(value),
      errorMessage: 'Invalid Email'
    }
  };

    
  const forgotEmailLoading = useSelector(state => state.auth.loading)
  const forgotEmailSuccess = useSelector(state => state.auth.success)
  const forgotEmailMsg = useSelector(state => state.auth.msg)
  const forgotEmailError = useSelector(state => state.auth.errors)


  useEffect(()=>{
    if(isWorking && updating){
      dispatch(forgotPassword(emailProperties.email));
      setUpdating(false)
    }
    if(isWorking && forgotEmailSuccess)
    {
      setIsWorking(false);
      setTimeout(() => {
        setRedirectNow(true);
      }, 5002);
    }
    else if(!forgotEmailLoading && forgotEmailError)
    {
      setIsWorking(false);
    }
    
  },[isWorking, forgotEmailLoading, forgotEmailSuccess, forgotEmailError, updating, dispatch, emailProperties.email])

  const functionTypeHandler = {
    emailProperties,
    setEmailProperties
  };

  const handleEnableSubmit = () => {
    return emailProperties.emailTouched && emailProperties.emailOkay;
  };

  const submitHandler = event => {
    event.preventDefault();
    inputBlurHandler(emailProperties.email, 'email');
    if (handleEnableSubmit()) {
      setIsWorking(true);
      setUpdating(true);

    }
  };
  const inputFocusHandler = controlName => {
    functionTypeHandler[`set${titleCaseConverter(controlName)}Properties`]({
      ...functionTypeHandler[`${controlName}Properties`],
      [`${controlName}Clicked`]: true
    });
  };

  const inputChangeHandler = (value, controlName) => {
    functionTypeHandler[`set${titleCaseConverter(controlName)}Properties`]({
      ...functionTypeHandler[`${controlName}Properties`],
      [`${controlName}`]: value,
      [`${controlName}Touched`]: true,
      [`${controlName}Okay`]: true
    });
  };

  const inputBlurHandler = (value, controlName) => {
    functionTypeHandler[`set${titleCaseConverter(controlName)}Properties`]({
      ...functionTypeHandler[`${controlName}Properties`],
      [`${controlName}`]: value,
      [`${controlName}Clicked`]: false,
      [`${controlName}Touched`]: true,
      [`${controlName}Okay`]: validators[controlName].validators(value)
    });
  };
  return (
    <div>
      <Container className='mt--8 pb-5'>
        <Row className='justify-content-center'>
          <Col lg='5' md='7'>
            <Card className='shadow'>
              <CardHeader className='px-lg-7 py-lg-6'>
                <div className=' text-primary text-uppercase h4'>
                  Forgot Password
                </div>
                <div className='text-center text-muted my-4'>
                  <img src={Logo} alt='logo' width='100%' />
                </div>
                {redirectNow ? <Redirect to='login' /> : null}
                {!forgotEmailLoading && forgotEmailSuccess ? (
                  <Alert color='info'>
                    <strong>{forgotEmailMsg}</strong>
                  </Alert>
                ) : !forgotEmailLoading && forgotEmailError? <Alert color='warning'>
                <strong>Warning!</strong> {forgotEmailMsg}
              </Alert>: (
                  <>
                    <Form
                      role='form'
                      onKeyPress={e =>
                        e.key === 'Enter' && handleEnableSubmit()
                          ? submitHandler(e)
                          : null
                      }
                    >
                      <FormGroup className='mb-3'>
                        <InputGroup className='input-group-alternative border-style'>
                          <InputGroupAddon addonType='prepend'>
                            <InputGroupText>
                              <img
                                alt='...'
                                src={require('../../assets/img/icons/mail.svg')}
                                width='80%'
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            autoComplete='off'
                            placeholder='Email'
                            type='email'
                            onFocus={() => inputFocusHandler('email')}
                            onChange={e => {
                              inputChangeHandler(e.target.value, 'email');
                            }}
                            onBlur={event =>
                              inputBlurHandler(event.target.value, 'email')
                            }
                          />
                        </InputGroup>
                        {emailProperties.emailTouched &&
                        !emailProperties.emailOkay &&
                        !emailProperties.emailClicked
                          ? simpleErrorMsg(validators['email'].errorMessage)
                          : null}
                      </FormGroup>
                      <div className='text-center'>
                        <Button
                          className='my-4 btn-block btn-border-style text-uppercase'
                          color='primary'
                          type='button'
                          onClick={submitHandler}
                          disabled={updating}
                        >
                          <span className='h3 text-white'>Send Email</span>
                        </Button>
                      </div>
                    </Form>
                  </>
                )}
                <div className='text-right'>
                  <Link to='login'>
                    <small>Login</small>
                  </Link>
                </div>
                {props.error ? (
                  <Alert color='warning'>
                    <strong>Warning!</strong> {props.errorMessage}
                  </Alert>
                ) : null}
              </CardHeader>
            </Card>
            <Row className='mt-3'>
              <Col xs='6'></Col>
            </Row>
          </Col>
        </Row>
        <Row className='justify-content-center font-weight-bold'>
          Powered by Infinichains &copy;
        </Row>
      </Container>
    </div>
  );
}
