import * as actionTypes from '../actions/action-types';
import { updateObject } from '../../shared/utility';

const initialState = {
  internalBatchesSuccess: null,
  internalBatchesLoading: false,
  internalBatchesError: null,
  internalBatchesMsg: null,
  duplicateBatches: []
};

const internalBatchesStart = (state, action) => {
  return updateObject(state, {
    internalBatchesError: null,
    internalBatchesLoading: true,
  });
};
const internalBatchesSuccess = (state, action) => {
  return updateObject(state, {
    internalBatchesError: null,
    internalBatchesLoading: false,
    internalBatchesSuccess: true,
    internalBatchesMsg: action.msg,
    duplicateBatches: action.duplicate_batches
  });
};

const internalBatchesFail = (state, action) => {
  return updateObject(state, {
    internalBatchesError: action.error,
    internalBatchesMsg: action.msg,
    internalBatchesLoading: false,
  });
};

const resetAddNewBatchesMessages = (state, action) => {
  return updateObject(state, {
    internalBatchesMsg: null,
    internalBatchesError: null,
    internalBatchesSuccess: null,
    internalBatchesLoading: false
  });
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INTERNAL_BATCHES_START:
      return internalBatchesStart(state, action);
    case actionTypes.INTERNAL_BATCHES_SUCCESS:
      return internalBatchesSuccess(state, action);
    case actionTypes.INTERNAL_BATCHES_FAIL:
      return internalBatchesFail(state, action);
      case actionTypes.RESET_ADD_NEW_BATCHES_MESSAGES:
        return resetAddNewBatchesMessages(state, action);
    default:
      return state;
  }
};
