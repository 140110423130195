import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useLocation } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from 'reactstrap';
import Logo from '../../assets/img/brand/login_logo.png';
import { simpleErrorMsg, titleCaseConverter } from '../../shared/utility';
import Loader from '../../shared/Loader/Loader';
import Validators from '../../shared/Validations';
import {checkResetPasswordToken, setPassword} from '../../store/actions/index';

export default function ResetPassword(props) {

  const location = useLocation()
  const dispatch = useDispatch();

  const [passwordProperties, setPasswordProperties] = useState({
    password: '',
    passwordClicked: false,
    passwordTouched: false,
    passwordOkay: false
  });
  const [confirmPasswordProperties, setConfirmPasswordProperties] = useState({
    confirmPassword: '',
    confirmPasswordClicked: false,
    confirmPasswordTouched: false,
    confirmPasswordOkay: false
  });
  const functionTypeHandler = {
    passwordProperties,
    confirmPasswordProperties,
    setPasswordProperties,
    setConfirmPasswordProperties
  };
  const [verifyTokenLoading, setVerifyTokenLoading] = useState(true);
  const [tokenOkay, setTokenOkay]= useState(false);
  const [updatePasswordSuccess, setUpdatePasswordSuccess] = useState(false);  
  const [isWorking, setIsWorking] = useState(false); 
  const [updating, setUpdating] = useState(false)
  const [redirectSuccess, setRedirectSuccess] = useState(false);
  const [redirectFail, setRedirectFail] = useState(false);

  // const [loading, setLoading] = useState(true);
  // const [success, setSuccess] = useState(null);
  // const [msg, setMsg] = useState(null);
  // const [error, setError] = useState(null)

  const resetLoading = useSelector(state => state.auth.loading)
  const resetSuccess = useSelector(state => state.auth.success)
  const resetError = useSelector(state => state.auth.errors)

  useEffect(()=>{
    if(verifyTokenLoading &&!isWorking)
    {
      dispatch(checkResetPasswordToken(new URLSearchParams(location.search).get('token')));
      setIsWorking(true)
    }
    if(verifyTokenLoading && isWorking && (!resetLoading && resetSuccess))
    {
      setTokenOkay(true)
      setIsWorking(false)
      setVerifyTokenLoading(false);
    }
    if(verifyTokenLoading && isWorking &&  (!resetLoading && resetError))
    {
      setTokenOkay(false)
      setIsWorking(false)
      setVerifyTokenLoading(false);
      setTimeout(() => {
        setRedirectFail(true);
      }, 5001);
    }
  },[verifyTokenLoading, isWorking, resetLoading, resetSuccess, resetError, dispatch, location.search])

  useEffect(()=>{
    if(isWorking && updating){
      dispatch(setPassword(passwordProperties.password,new URLSearchParams(location.search).get('token') ));
      setIsWorking(false);

    }
    if(!resetLoading && resetSuccess && updating)
    {
      setUpdating(false);
      setUpdatePasswordSuccess(true)
      setTimeout(() => {
        setRedirectSuccess(true);
      }, 5001);
    }
    else if(!resetLoading && resetError && updating)
    {
      setUpdating(false);
    }
  },[isWorking, updating, resetLoading, resetSuccess, resetError, dispatch, passwordProperties.password, location.search])

  const validators = {
    password: {
      validators: (value, cValue = '') => Validators.fieldRequired(value, 7),
      errorMessage: 'Password is less than 8 characters'
    },
    confirmPassword: {
      validators: (value, cValue) => Validators.fieldMatch(value, cValue),
      errorMessage: 'Passwords are not matching'
    }
  };
  
  const inputFocusHandler = controlName => {
    functionTypeHandler[`set${titleCaseConverter(controlName)}Properties`]({
      ...functionTypeHandler[`${controlName}Properties`],
      [`${controlName}Clicked`]: true
    });
  };

  const inputChangeHandler = (value, controlName) => {
    functionTypeHandler[`set${titleCaseConverter(controlName)}Properties`]({
      ...functionTypeHandler[`${controlName}Properties`],
      [`${controlName}`]: value,
      [`${controlName}Touched`]: true,
      [`${controlName}Okay`]: true
    });
  };

  const inputBlurHandler = (value, controlName, cValue = '') => {
    functionTypeHandler[`set${titleCaseConverter(controlName)}Properties`]({
      ...functionTypeHandler[`${controlName}Properties`],
      [`${controlName}`]: value,
      [`${controlName}Clicked`]: false,
      [`${controlName}Touched`]: true,
      [`${controlName}Okay`]: validators[controlName].validators(value, cValue)
    });
  };

  const handleEnableSubmit = () => {
    return (
      passwordProperties.passwordTouched && passwordProperties.passwordOkay,
      confirmPasswordProperties.confirmPasswordTouched &&
        confirmPasswordProperties.confirmPasswordOkay
    );
  };

  const submitHandler = event => {
    event.preventDefault();
    inputBlurHandler(passwordProperties.password, 'password');
    inputBlurHandler(
      confirmPasswordProperties.confirmPassword,
      'confirmPassword',
      passwordProperties.password
    );
    if (handleEnableSubmit()) {
      setIsWorking(true);
      setUpdating(true);
    }
  };

  return (
    <div>
      <Container className='mt--8 pb-5'>
        <Row className='justify-content-center'>
          <Col lg='5' md='7'>
            <Card className='shadow'>
              <CardHeader className='px-lg-7 py-lg-6'>
                <div className=' text-primary text-uppercase h4'>
                  Reset Password
                </div>
                <div className='text-center text-muted my-4'>
                  <img src={Logo} alt='logo' width='100%' />
                </div>
                {redirectSuccess ? <Redirect to='login' /> : null}
                {redirectFail? <Redirect to="forgotpassword" />:null}
                { updatePasswordSuccess? (
                  <Alert color='success'>
                    <strong>Password Updated Successfully!</strong>
                  </Alert>
                ) : tokenOkay?  (
                  <>
                    <Form
                      role='form'
                      onKeyPress={e =>
                        e.key === 'Enter' && handleEnableSubmit()
                          ? submitHandler(e)
                          : null
                      }
                    >
                      <FormGroup className='mb-3'>
                        <InputGroup className='input-group-alternative border-style'>
                          <InputGroupAddon addonType='prepend'>
                            <InputGroupText>
                              <img
                                alt='...'
                                src={require('../../assets/img/icons/lock.svg')}
                                width='80%'
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            autoComplete='off'
                            placeholder='New Password'
                            type='password'
                            onFocus={() => inputFocusHandler('password')}
                            onChange={e => {
                              inputChangeHandler(e.target.value, 'password');
                            }}
                            onBlur={event =>
                              inputBlurHandler(event.target.value, 'password')
                            }
                          />
                        </InputGroup>
                        {passwordProperties.passwordTouched &&
                        !passwordProperties.passwordOkay &&
                        !passwordProperties.passwordClicked
                          ? simpleErrorMsg(validators['password'].errorMessage)
                          : null}
                      </FormGroup>
                      <FormGroup className='mb-3'>
                        <InputGroup className='input-group-alternative border-style'>
                          <InputGroupAddon addonType='prepend'>
                            <InputGroupText>
                              <img
                                alt='...'
                                src={require('../../assets/img/icons/lock.svg')}
                                width='80%'
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            autoComplete='off'
                            placeholder='Confirm Password'
                            type='password'
                            onFocus={() => inputFocusHandler('confirmPassword')}
                            onChange={e => {
                              inputChangeHandler(
                                e.target.value,
                                'confirmPassword'
                              );
                            }}
                            onBlur={event =>
                              inputBlurHandler(
                                event.target.value,
                                'confirmPassword',
                                passwordProperties.password
                              )
                            }
                          />
                        </InputGroup>
                        {confirmPasswordProperties.confirmPasswordTouched &&
                        !confirmPasswordProperties.confirmPasswordOkay &&
                        !confirmPasswordProperties.confirmPasswordClicked
                          ? simpleErrorMsg(
                              validators['confirmPassword'].errorMessage
                            )
                          : null}
                      </FormGroup>
                      <div className='text-center'>
                        <Button
                          className='my-4 btn-block btn-border-style text-uppercase'
                          color='primary'
                          type='button'
                          onClick={submitHandler}
                          disabled={updating}
                        >
                          <span className='h3 text-white'>Set New Password</span>
                        </Button>
                      </div>
                    </Form>
                  </>
                ):!tokenOkay && !isWorking && !verifyTokenLoading? <Alert color='warning'>
                <strong>Warning!</strong> {`Invalid Reset Link`}
              </Alert>:<><div className="d-flex justify-content-center"><Loader /></div></> }
              {
                !isWorking && !resetLoading && resetError && !updating && tokenOkay ?
                <Alert color='warning'>
                <strong>Warning!</strong> {`Something Went Wrong!`}
              </Alert>:null
              }
                <div className='text-right'>
                  <Link to='login'>
                    <small>Login</small>
                  </Link>
                </div>
              </CardHeader>
            </Card>
            <Row className='mt-3'>
              <Col xs='6'></Col>
            </Row>
          </Col>
        </Row>
        <Row className='justify-content-center font-weight-bold'>
          Powered by Infinichains &copy;
        </Row>
      </Container>
    </div>
  );
}
