import React from 'react';
import ReactGA from 'react-ga';

export const updateObject = (oldObject, newProperties) => ({
  ...oldObject,
  ...newProperties
});

export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = value.trim() !== "" && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail) {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }

  return isValid;
};

export const headers = () => ({
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getFromLocalStorage("token")}`
    // Authorization: 1
  }
});

export const saveInLocalStorage = (varname, value) => {
  localStorage.setItem(btoa(varname), btoa(value))
  //localStorage.setItem((varname), (value))

}

export const removeFromLocalStorage = (varname) => {
  localStorage.removeItem(btoa(varname))
  //localStorage.removeItem((varname))

}

export const getFromLocalStorage = (varname) => {
  // console.log(atob(localStorage.getItem(btoa(varname))))
  return atob(localStorage.getItem(btoa(varname)))
  //return (localStorage.getItem((varname)))

}


export const backendRoutes = {
  register: '/api/register',
  login: '/api/login',
  forgotPassword:(email) => `/api/validation_link?email=${email}`,
  verifyToken: (token) => `/api/verify?token=${token}`,
  setPassword: `/api/set_password`,
  getAllUserDepartments: (userId) => `/api/users/${userId}/departments`,
  getAllDepartmentsByUser: (loggedInUserId) => `api/users/${loggedInUserId}/departments`,
  getDepartmentById: (deptId) => `api/departments/${deptId}`,
  getDepartmentByIdforUser: (deptId, userId) => `/api/users/${userId}/departments/${deptId}`,
  getBatchByIdforUser: (batchId, deptId) => `/api/departments/${deptId}/batches/${stringEncoder(batchId)}`,
  addNewActivity: (batchId) => `api/batches/${batchId}/activities`,
  approveBatchByNumber: (batchNumber) => `/api/batches/${batchNumber}/approve`,
  exportBatchByStageName: (stageName) => `/api/batches/${stageName}/export`,
  getTraceabilitySO: (soNumber) => `api/sales_order/${soNumber}/drilldown`,
  getAllSO: `api/sales_order`,
  getBalesByNumber: (baleLotNumber) => `/api/batches/${baleLotNumber}`,
  printQRCodePDF: (so_line_item_id) => `/api/sales_order/qr_code/${so_line_item_id}/print/`,
  generateQrCode: (soLineItem) => `/api/sales_order/${soLineItem}`,
  getDocuments: `/api/certificates`,
  downloadDocuments: (queryParams) => encodeURI(`/api/files/?url=${queryParams}`),
  users:'/api/users',
  userRoles: '/api/users/roles',
  userStatus: '/api/users/status',
  requestBatches : `/api/batches/external/request`,
  externalBatches: '/api/batches/external/import',
  addInternalBatches: (stageName) => `/api/batches/${stageName}`,
  getTransferData: (token) =>  `/api/batches/external/verify?token=${token}`,
  resetDemoData: `/api/credible_core/demo/reset`,
}

export const messageList = {
  error: 'Oops! There seems to be some error! Please try again.'
}

export const simpleErrorMsg = (message) => {
  return <div className="text-danger h5">
      <span>{message}</span>
    </div>
}

export const getBase64Image = (img) => {
  let imgImage = document.createElement('img');
  imgImage.src=img.src;
  var canvas = document.createElement("canvas");
  canvas.width = imgImage.width;
  canvas.height = imgImage.height;
  var ctx = canvas.getContext("2d");
  ctx.drawImage(imgImage, 0, 0);
  // var dataURL = canvas.toDataURL("image/png");
  return canvas.toDataURL("image/png");
  // return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
}

export const imageProcess = (src, ctx, x,y,vx,vy) => {
  return new Promise((resolve,reject)=>{
    let img = new Image();
    img.src = src;
    img.onload = function(){
      ctx.drawImage(img,x,y,vx,vy);
      resolve(ctx)
    }
  })
}

export const permissionsManager = (role) => {
  switch(role) {
    case 'viewer': 
    return 1
    case 'operator':
      return 2
    case 'approver':
      return 3
    default:
      return 0
  }
}

export const stringEncoder = (string) => string.toString().replace(/\//g,'%2F')


export const eventTracker = (category, action, label=null) => {
  var eventObject = {category, action}
  if(label) eventObject.label = label;
  ReactGA.event(eventObject)
}

export const eventAction = {
  exportClicked: 'Export Data Button Clicked',
  approveClicked: 'Approve Button Clicked',
  addDocClicked: 'Add Document Button Clicked',
  viewDocClicked: 'View Document Button Clicked'
}

export const titleCaseConverter = (string) => string.replace(string[0],string[0].toUpperCase())

export const rolesList = ['Approver','Operator','Viewer']

export const routeGenerator = () => {
  let user_type = getFromLocalStorage('user_type').toLowerCase();
  let user_role = getFromLocalStorage('user_role').toLowerCase();
  if(user_role==='admin')
  return [
    {
      layout: '/admin/user',
      path: '',
      name: `Admin`,
      routes: [
        {
          layout: '/admin/user',
          path: '/users',
          name: `User`
        },
        // {
        //   layout: '/admin',
        //   path: '/productline',
        //   name: `Product Line`
        // },
        // {
        //   layout: '/admin',
        //   path: '/systemsettings',
        //   name: `System Settings`
        // }
      ]
    },
    {
      layout: '/admin/departments',
      path: '/index',
      name: `Departments`
    },
    {
      layout: '/admin/traceability',
      path: '/drilldown',
      name: 'Traceability'
    },
    {
      layout: '/admin/certificates',
      path: '/document',
      name: `Certificates`
    }
  ];
  else if(user_role==='user'){
    if(user_type==='owner') 
    return [
      {
        layout: '/admin/departments',
        path: '/index',
        name: `Departments`
      },
      {
        layout: '/admin/traceability',
        path: '/drilldown',
        name: 'Traceability'
      },
      {
        layout: '/admin/certificates',
        path: '/document',
        name: `Certificates`
      }
    ];
    else if(user_type==='customer')
    return [
      {
        layout: '/admin/traceability',
        path: '/drilldown',
        name: 'Traceability'
      },
      {
        layout: '/admin/certificates',
        path: '/document',
        name: `Certificates`
      }
    ]
    else return []
  }
  else return []
}

export const fileTypeValidator = (dataType) => {
  switch (dataType) {
    case 'data:application/vnd.ms-excel':
      return 'ms-excel-old';
    case 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'ms-excel';
    default:
      return 'others';
  }
};