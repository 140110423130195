/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

class Login extends React.Component {
  render() {
    return (
      <>
        <footer className="pb-5 pt-1 footer bg-light">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col col-lg-8">
              Terms and Conditions | Copyrights@InfiniChains | 2273 Howard Ave, San Carlos, California, USA
              | Gala Impecca, Andheri East, Mumbai, India | Viman Nagar, Pune, India 
               {/* <i className="fas fa-phone" /> +1 21 2620 2000 | <i className="fas fa-fax" /> +1 21 2696 2831 */}
              </div>
            </div>
          </div>
          {/* </Row>
          </Container> */}
        </footer>
      </>
    );
  }
}

export default Login;
