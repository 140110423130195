import React, { Component } from 'react';
import {
  Col,
  FormGroup,
  Row,
  DropdownItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import { isNumber } from 'util';
import { rolesList } from './utility';
export class AddDepartment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpenDepartment: false,
      dropdownOpenRole: false,
      chosenDepartment: {},
      rolesList: rolesList,
      enableAddMore:true,
      chooseDepartmentOkay:false,
      chooseDepartmentTouched:false,
      chooseRoleOkay:false,
      chooseRoleTouched:false
    };
    this.validators = {
      chooseDepartment: {
        validators: (value) => (isNumber(value) ? true : false),
        errorMessage: 'Department is Required',
      },
      chooseRole: {
        validators: (value) => (isNumber(value) ? true : false),
        errorMessage: 'Role is Required',
      },
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if(!prevState.chosenDepartment.hasOwnProperty('name') && nextProps.userDetails && nextProps.userDetails[nextProps.index])
    {
        return {
            chosenDepartment: nextProps.userDetails[nextProps.index].chosenDepartment
        }
    }
    else return null
  }


  setDepartment = (dept) => {
    if (dept.name === 'All') {   
      this.setState(
        {
          chosenDepartment: dept,
          enableAddMore: false,
          chooseDepartmentOkay:true,
          chooseDepartmentTouched:true,
          chosenRole:this.state.chosenRole?this.state.chosenRole:this.props.userDetails && this.props.userDetails[this.props.index] &&
            this.props.userDetails[this.props.index].chosenRole?
          this.props.userDetails[this.props.index].chosenRole.role:'Choose Role'
        },
        () => {
          this.props.trackChosenDepartment({
            chosenRole:this.state.chosenRole?this.state.chosenRole:this.props.userDetails && this.props.userDetails[this.props.index] &&
            this.props.userDetails[this.props.index].chosenRole?
          this.props.userDetails[this.props.index].chosenRole.role:'Choose Role',
            index: this.props.index,
            state: this.state,
            disableAddMore: false,
            chooseDepartmentOkay:true,
          chooseDepartmentTouched:true,
          });
        }
      );
    } else {
      
      this.setState(
        {
          chosenDepartment: dept,
          enableAddMore: true,
          chooseDepartmentOkay:true,
          chooseDepartmentTouched:true,
          chosenRole:this.state.chosenRole?this.state.chosenRole:this.props.userDetails && this.props.userDetails[this.props.index] &&
            this.props.userDetails[this.props.index].chosenRole?
          this.props.userDetails[this.props.index].chosenRole.role:'Choose Role'
                },
        () => {
          this.props.trackChosenDepartment({
            chosenRole:this.state.chosenRole?this.state.chosenRole:this.props.userDetails && this.props.userDetails[this.props.index] &&
            this.props.userDetails[this.props.index].chosenRole?
          this.props.userDetails[this.props.index].chosenRole.role:'Choose Role',
            index: this.props.index,
            state: this.state,
            disableAddMore: true,
            chooseDepartmentOkay:true,
          chooseDepartmentTouched:true,
          });
        }
      );
    }
  };
  setRole = (role) => {
    
    this.setState(
      {
        chosenRole: role,
        dropdownOpenRole:true,
        chooseRoleOkay:true,
        chooseRoleTouched:true,
      },
      () => {
        this.props.trackChosenDepartment({
          chosenRole:this.state.chosenRole?this.state.chosenRole:this.props.userDetails && this.props.userDetails[this.props.index] &&
            this.props.userDetails[this.props.index].chosenRole?
          this.props.userDetails[this.props.index].chosenRole.role:null,
          index: this.props.index,
          state: this.state,
          chooseRoleTouched:true,
         chooseRoleOkay:true
        });
      }
    );
  };

  render() {
    let {
      chosenDepartment,
      dropdownOpenDepartment,
      dropdownOpenRole,
    } = this.state;
    return (
      <div>
        <FormGroup>
          <Row>
            <Col className='col-md-8 col-lg-4 col-sm-8 col-12 mt-3'>
              <Dropdown
                size='lg'
              className="w-100 border rounded border-light"
                isOpen={dropdownOpenDepartment}
                toggle={() => {
                  this.setState({
                    dropdownOpenDepartment: !dropdownOpenDepartment,
                  });
                }}
              >
                <DropdownToggle
                className="w-100 text-truncate"
                color='light'
                  disabled={
                    this.props.index + 1 < this.props.addUserRenderer.length
                  }
                  size='lg'
                  caret
                >
                  {
                  // chosenDepartment.hasOwnProperty('name')
                  //   ? chosenDepartment.name 
                  //   : chosenDepartment.length
                  //   ? chosenDepartment
                    // : 
                    this.props.userDetails && this.props.userDetails[this.props.index] &&
                      this.props.userDetails[this.props.index].chosenDepartment
                        .name
                    ? this.props.userDetails[this.props.index].chosenDepartment
                        .name:chosenDepartment.hasOwnProperty('name')
                        ? chosenDepartment.name 
                    : 'Choose Department'}
                </DropdownToggle>
                <DropdownMenu size='lg'>
                  <DropdownItem
                    value='All'
                    size='lg'
                    hidden={
                      this.props.addUserRenderer.length > 1 ? true : false
                    }
                    onClick={() => this.setDepartment({ name: 'All' })}
                  >
                    All
                  </DropdownItem>
                  {this.props.departmentList.map((dept, index) => (
                    <DropdownItem
                      key={index}
                      size='lg'
                      value={dept.name}
                      onClick={() => this.setDepartment(dept)}
                    >
                      {dept.name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
              {this.props.chooseDepartmentTouched &&
              !this.props.chooseDepartmentOkay ? (
                <span className='text-danger'>
                  <small>{this.validators.chooseDepartment.errorMessage}</small>
                </span>
              ) : null}
            </Col>
            <Col className='col-md-2'></Col>
            <Col className='col-md-8 col-lg-4 col-sm-8 col-12 mt-3'>
              <Dropdown
              className="w-100 border rounded border-light"
                size='lg'
                isOpen={dropdownOpenRole}
                toggle={() => {
                  this.setState({
                    dropdownOpenRole: !dropdownOpenRole,
                  });
                }}
              >
                <DropdownToggle size='lg'
                className="w-100 text-truncate" caret color="light">
                  {
                  this.state.chosenRole
                    ? this.state.chosenRole
                    :this.props.userDetails && this.props.userDetails[this.props.index] 
                  && this.props.userDetails[this.props.index].chosenRole.role?
                  this.props.userDetails[this.props.index].chosenRole.role
                  // this.props.userDetails[this.props.index].chosenRole.role
                  // :
                  // this.props.userDetails && this.props.userDetails[this.props.index] &&
                  //   this.props.userDetails[this.props.index].chosenDepartment?
                  // this.props.userDetails[this.props.index].chosenDepartment.role
                  : 'Choose Role'}
                </DropdownToggle>
                <DropdownMenu size='lg'>
                  {this.state.rolesList.map((role, index) => (
                    <DropdownItem
                      key={index}
                      size='lg'
                      value={role}
                      onClick={() => this.setRole(role)}
                    >
                      {role}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </Col>
            <Col className='col-md-1 mt-2 ml-2'>
              {/* {!(this.props.index + 1 < this.props.addUserRenderer.length) ? ( */}
                <button
                  aria-label='Close'
                  className='close'
                  data-dismiss='modal'
                  type='button'
                  onClick={() =>
                    this.props.removeDocAddWindow(
                      this.props.index,
                      this.state.chosenDepartment.name
                    )
                  }
                >
                  <span aria-hidden={true} className='float-right mt-4'>
                    ×
                  </span>
                </button>
               {/* ) : null}  */}
            </Col>
          </Row>
        </FormGroup>
       
      </div>
    );
  }
}

export default AddDepartment;
