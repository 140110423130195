import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table
} from 'reactstrap';
import AdminFooter from '../../components/Footers/AdminFooter';
import Error from '../../shared/Error/Error';
import Loader from '../../shared/Loader/Loader';
import { downloadDocument } from '../../shared/Services';
import Tables from '../../shared/Tables/Tables';
import { eventAction, eventTracker, messageList } from '../../shared/utility';
import * as actions from '../../store/actions/index';
export default function Bales() {
  const baleId = window.location.pathname.split('bales/')[1];
  const dispatch = useDispatch();
  const [targetBales, setTargetBales] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const tableHeaders = [
    { Header: 'RFID Tag No.', accessor: 'bale_number' },
    { Header: 'Vendor Name', accessor: 'metadata.vendor_name' },
    {
      Header: 'Packed Weight',
      accessor: 'base_quantity'
    },
    {
      Header: 'Unit',
      accessor: 'base_unit'
    },
    { Header: 'Delivery No.', accessor: 'metadata.delivery_number' },
    { Header: 'Material', accessor: 'metadata.material' },
    {
      Header: 'Material Description',
      accessor: 'metadata.material_description'
    },
    { Header: 'Purchase Order', accessor: 'metadata.purchase_order' },
    { Header: 'Invoice No.', accessor: 'metadata.invoice_number' },
    { Header: 'PO Item', accessor: 'metadata.po_item' }
  ];

  useEffect(() => {
    dispatch(actions.getBalesByNumber(baleId));
  }, [dispatch, baleId]);

  const balesList = useSelector(state => state.traceability.baleLot);
  const balesDocs = useSelector(state => state.traceability.baleLotDocs);
  const balesLoading = useSelector(state => state.traceability.baleLotLoading);
  const balesSuccess = useSelector(state => state.traceability.baleLotSuccess);
  const balesError = useSelector(state => state.traceability.baleLotErrors);
  useEffect(() => {
    setTargetBales(balesList);
  }, [balesList]);

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const viewDocument = (url, name) => {
    eventTracker(
      `Bales - ${baleId}`,
      eventAction.viewDocClicked,
      `View ${name}`
    );
    downloadDocument(url, name);
  };
  // console.log(targetBales)
  return (
    <React.Fragment>
      {/* <Tables headers={tableHeaders} /> */}
      <div className='container-fluid main-content pb-5 reset-left-margin'>
        {/* <AdminNavbar /> */}
        <div className='header py-2'>
          <Container>
            <div className='header-body text-center mb-5'>
              <Row className='justify-content-center mt-5'>
                <Col lg='5' md='6'></Col>
              </Row>
            </div>
          </Container>
          <div className='separator separator-bottom separator-skew'></div>
        </div>
        <Container fluid className='mt--1 reset-margin mb-5 pb-5'>
          <div>
            <span className='clearfix' />
            <Row>
              <Col lg='12' md='12' sm='12' xs='12'>
                <div className='d-flex'>
                  <div className='mr-auto'>
                    <span className='h1 mb-2 text-primary font-weight-bold text-capitalize'>
                      List of Bales for Bale Lot ID: {baleId}
                    </span>
                  </div>
                  <div className='ml-auto text-primary border-bottom'>
                    <Button
                      className='btn-border-style-outline btn-outline-primary m-0'
                      type='button'
                      onClick={toggleModal}
                    >
                      View Certificates
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <div className='pb-4'></div>
            <Modal size='lg' isOpen={openModal} toggle={toggleModal}>
              <ModalHeader toggle={toggleModal}>
                <span className=' h3'>Certificates for Bale ID {baleId}</span>
              </ModalHeader>
              <ModalBody>
                {balesDocs.length ? (
                  <Table striped responsive>
                    <thead className='text-left'>
                      <tr>
                        <th scope='col'>Name</th>
                        <th scope='col'>Description</th>
                        <th scope='col'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {balesDocs.map((doc, index) => (
                        <tr key={index}>
                          <td className='font-weight-bold'>{doc.name}</td>
                          <td>{doc.description}</td>
                          <td className='text-titlecase'>
                            <span
                              className='text-primary showpointer'
                              onClick={() => viewDocument(doc.url, doc.name)}
                            >
                              View
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div align='center'>
                    <span className='font-weight-bold text-center'>
                      No documents available{' '}
                    </span>
                  </div>
                )}
              </ModalBody>
            </Modal>
            <div className='pb-4'></div>

            {balesLoading ? (
              <Loader />
            ) : !balesLoading && balesSuccess ? (
              targetBales.length ? (
                <>
                  <Tables data={targetBales} headers={tableHeaders} />
                  {/* <Table responsive striped>
                    <thead>
                      <tr>
                        {headers.map((head, i) => (
                          <th style={{ width: '10%' }} key={i}>
                            {head.toUpperCase()}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {currentBales.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <strong>{data.bale_number}</strong>
                            </td>
                            <td>{data.metadata.vendor_name}</td>
                            <td>
                              {parseFloat(data.base_quantity).toFixed(3)}{' '}
                              {data.base_unit}
                            </td>
                            <td>{data.metadata.delivery_number}</td>
                            <td>{data.metadata.material}</td>
                            <td>{data.metadata.material_description}</td>
                            <td>{data.metadata.purchase_order}</td>
                            <td>{data.metadata.invoice_number}</td>
                            <td>{data.metadata.po_item}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table> */}
                  {/* <Paginate
                    currentPage={parseInt(currentPage)}
                    paginate={paginate}
                    targetArrayLength={targetBales.length}
                  /> */}
                </>
              ) : (
                <span className='h3'>Bales not Available</span>
              )
            ) : !balesLoading && balesError ? (
              <Error message={messageList.error} />
            ) : null}
          </div>
        </Container>
        <AdminFooter />
      </div>
    </React.Fragment>
  );
}
