import * as actionTypes from './action-types';
import { api } from '../../axios-instance';
import { headers, backendRoutes } from '../../shared/utility';

export const createUserStart = (_) => ({
  type: actionTypes.CREATE_USER_START,
});

export const createUserSuccess = (user, msg) => ({
  type: actionTypes.CREATE_USER_SUCCESS,
  user,
  msg,
});

export const createUserFail = (error, msg) => ({
  type: actionTypes.CREATE_USER_FAIL,
  error,
  msg,
});
export const resetMessages = () => ({
  type: actionTypes.RESET_MESSAGES
});
export const resetAlerts = (timer = 3000) => {
  return dispatch => {
    setTimeout(() => {
      dispatch(resetMessages());
    }, timer);
  };
}
export const createUser = (data) => {
  return (dispatch) => {
    dispatch(createUserStart());
    api
      .post(backendRoutes.users, data, headers())
      .then((response) => {
        let { payload } = response.data.result;
        dispatch(createUserSuccess(payload, response.data.result.msg));
        dispatch(getUsers())
        dispatch(resetAlerts());
      })
      .catch((error) => {
        dispatch(createUserFail(error, error.response.data.result.msg));
        dispatch(resetAlerts());
      });
  };
};

export const getUsersStart = (_) => ({
  type: actionTypes.GET_USERS_START,
});

export const getUsersSuccess = (users, msg) => ({
  type: actionTypes.GET_USERS_SUCCESS,
  users,
  msg,
});

export const getUsersFail = (error, msg) => ({
  type: actionTypes.GET_USERS_FAIL,
  error,
  msg,
});

export const getUsers = () => {
  return (dispatch) => {
    dispatch(getUsersStart());
    api
      .get(backendRoutes.users, headers())
      .then((response) => {
        let { payload } = response.data.result;
        dispatch(getUsersSuccess(payload, response.data.result.msg));
      })
      .catch((error) => {
        dispatch(getUsersFail(error, error.message));
      });
  };
};

export const updateUserStart = (_) => ({
  type: actionTypes.UPDATE_USER_START,
});

export const updateUserSuccess = (user, msg) => ({
  type: actionTypes.UPDATE_USER_SUCCESS,
  user,
  msg,
});

export const updateUserFail = (error, msg) => ({
  type: actionTypes.UPDATE_USER_FAIL,
  error,
  msg,
});

export const updateUser = (data, userStatusData) => {
  return (dispatch) => {
    dispatch(updateUserStart());
    if(data.hasOwnProperty('email') && userStatusData.hasOwnProperty('email'))
    {
      let e1=api.put(backendRoutes.userRoles, data, headers())
      let e2=api.post(backendRoutes.userStatus, userStatusData, headers())
      Promise.all([e1,e2]).then((response)=>{
        let { payload } = response[0].data.result;
        dispatch(updateUserSuccess(payload,response[0].data.result.msg))
        dispatch(getUsers())
      }).catch((error)=>{
        dispatch(updateUserFail(error, error.message));
      })
    }
    else{
      if(data.hasOwnProperty('email'))
      {
        api.put(backendRoutes.userRoles, data, headers())
        .then((response)=>{
          let { payload } = response.data.result;
          dispatch(updateUserSuccess(payload,response.data.result.msg))
          dispatch(getUsers())
        }).catch((error)=>{
          dispatch(updateUserFail(error, error.message));
        })
      }
      else if(userStatusData.hasOwnProperty('email')){
        api.post(backendRoutes.userStatus, userStatusData, headers())
        .then((response)=>{
          let { payload } = response.data.result;
          dispatch(updateUserSuccess(payload,response.data.result.msg))
          dispatch(getUsers())
        }).catch((error)=>{
          dispatch(updateUserFail(error, error.message));
        })
      }
    }
    
  };
};