import ReactEcharts from 'echarts-for-react';
import echarts from 'echarts/lib/echarts';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'reactstrap';
import * as actions from '../../store/actions/index';
import Loader from '../../shared/Loader/Loader';
import Error from '../../shared/Error/Error';
import { messageList } from '../../shared/utility';
export default function Echart(props) {
  var previousDepth = '';
  var previousDepthValue = '';
  const stages = [
    'sales_order',
    'dyelot',
    'rope_batch',
    'doff_batch',
    'beam_batch',
    'yarn_batch',
    'mixing_batch',
    'bale_lot'
  ];
  const [expandCollapse, setExpandCollapse] = useState({
    isChecked: false,
    initialTreeDept: 2,
    expandAndCollapse: true,
    height: 900
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getTraceabilitySO(props.so));
    handleExpandCollapse(false,2);
    if(document.getElementsByClassName('echarts-for-react').length)
    document
    .getElementsByClassName('echarts-for-react')[0]
    .setAttribute(
      'style',
      `height:900px`)
  }, [dispatch, props.so]);

  const drillDownData = useSelector(state => state.traceability.traceability_so);

  const traceabilitySoSuccess = useSelector(state => state.traceability.traceabilitySoSuccess);
  const traceabilitySoLoading = useSelector(state => state.traceability.traceabilitySoLoading);
  const traceabilitySoErrors = useSelector(state => state.traceability.traceabilitySoErrors);

  const handleExpandCollapse = (checkedValue, depth) => {
    if (checkedValue) {
      setExpandCollapse({
        isChecked: checkedValue,
        initialTreeDept: depth,
        expandAndCollapse: false,
        height: '2500px'
      });
    } else {
      setExpandCollapse({
        isChecked: checkedValue,
        initialTreeDept: 2,
        expandAndCollapse: true,
        height: '900px'
      });
    }
  };

  const ondblclick = params => {
    if (params.data.name.toLowerCase() === 'balelot' && params.data.value === "ICBL900441" && !localStorage.getItem('ICBL900441')) {
      window.open(
        `${window.location.origin}/admin/departments/${params.data.department}/batches/${params.data.value}`,
        '_blank'
      );
    }
  }
  const onClick = params => {
    if(params.data.children.length && !expandCollapse.isChecked)
    {
      let currentDepth = params.data.stage.toLowerCase().replace(/ /g, '_');
    let currentDepthValue = params.data.value;
    let currentDepthLevel = stages.indexOf(currentDepth);
    let previousDepthLevel = stages.indexOf(previousDepth);
    let expectedHeightIncrement = 0;
    let expectedHeightDecrement = 0;
    if (currentDepthLevel > previousDepthLevel) {
      if (currentDepthValue === previousDepthValue) {
        previousDepth = currentDepth;
        expectedHeightIncrement =
          (currentDepthLevel - previousDepthLevel) * 100;
        expectedHeightDecrement = 0;
        previousDepthValue = currentDepthValue;
      } else {
        previousDepth = currentDepth;
        expectedHeightIncrement =
          (currentDepthLevel - previousDepthLevel) * 200;
        expectedHeightDecrement = 0;
        previousDepthValue = currentDepthValue;
      }
    } else if (currentDepthLevel < previousDepthLevel) {
      if (currentDepthValue === previousDepthValue) {
        previousDepth = currentDepth;
        expectedHeightIncrement = 0;
        expectedHeightDecrement =
          (previousDepthLevel - currentDepthLevel) * 100;
        previousDepthValue = currentDepthValue;
      } else {
        previousDepth = currentDepth;
        expectedHeightIncrement = 0;
        expectedHeightDecrement =
          (previousDepthLevel - currentDepthLevel) * 200;
        previousDepthValue = currentDepthValue;
      }
    } else {
      if(currentDepthValue!==previousDepthValue)
      {
        expectedHeightIncrement=100
        expectedHeightDecrement=0
      }
      else
      {
        previousDepthValue = currentDepthValue;
        previousDepth = currentDepth;
      }
      
    }

    let currentHeight = parseInt(
      document
        .getElementsByClassName('echarts-for-react')[0]
        .getAttribute('style')
        .split(':')[1]
        .split('px')[0]
        .trim()
    );
    document
      .getElementsByClassName('echarts-for-react')[0]
      .setAttribute(
        'style',
        `height:${
          expectedHeightIncrement
            ? currentHeight + expectedHeightIncrement
            : currentHeight - expectedHeightDecrement
        }px`
      );
    }
    if (params.data.name.toLowerCase() === 'bale lot') {
      window.open(
        `${window.location.origin}/bales/${params.data.value}`,
        '_blank'
      );
    }
  };

  const getOption = drillDownData => {
    return {
      tooltip: {
          trigger: "item",
          triggerOn: "mouseclick",
          borderColor:'transparent',
          formatter: function (params) {
              // console.log(JSON.stringify(params.data));
              if(!params.data.children.length && params.data.name.toLowerCase() === 'balelot' && params.data.value === "ICBL900441")
              return (
                `<div class="bg-secondary text-white p-1 h5">
                    ${params.data.name} #${params.data.value} is an outsourced item.<br />Double Click to request Traceability data.<br /> 
                </div>`
              )
              else if(!params.data.children.length && params.data.name.toLowerCase() !== 'bale lot')
              {
                return (
                  `<div class="bg-secondary text-white p-1 h5">
                      ${params.data.name} #${params.data.value} cannot be further expanded.<br /> 
                  </div>`
                );
              }
              
          },
          textStyle: {
              fontSize: 12
          },
      },
      series: [
        {
          type: 'tree',
          data: [drillDownData],
          top: '1%',
          left: '12%',
          bottom: '1%',
          right: '15%',
          symbolSize: 32, //Root Node Symbol Size (Replace with company logo)
          lineStyle: {
            color: '#848688',
            opacity: 0.1,
            width: 1
          },
          silent: false, //if set to true, mouse clicks are disabled
          itemStyle: {
            color: '#00B5F0',
            opacity: 1,
            borderColor: '#00B5F0'
          },
          label: {
            normal: {
              color: '#848688',
              fontWeight: 'bold',
              opacity: 1,
              position: 'top',
              verticalAlign: 'middle',
              align: 'right',
              fontSize: 14,
              padding: [10, 5],
              distance: 5,
              formatter: function(params) {
                if (params.data !== null && params.data.farmer_name) {
                  return params.data.farmer_name;
                } else if (params.name.toLowerCase() === 'bale lot') {
                  return `${params.name} - ${params.value}`;
                } else {
                  return `${params.name}\n(${params.value})`;
                }
              }
            }
          },

          leaves: {
            label: {
              normal: {
                color: '#848688',
                fontWeight: 'bold',
                position: 'right',
                verticalAlign: 'middle',
                align: 'left',
                opacity: 1
              }
            }
          },
          initialTreeDept: expandCollapse.initialTreeDept,
          expandAndCollapse: expandCollapse.expandAndCollapse,
          animationDuration: 550,
          animationDurationUpdate: 750
        }
      ]
    };
  };

  let onEvents = {
    click: onClick,
    dblclick: ondblclick
  };
  return (
    <>
    {traceabilitySoLoading? <Loader />
    : !traceabilitySoLoading && traceabilitySoSuccess?
    drillDownData ? (
      <div className='mt-5'>
        <Row className='h2 mb-2 text-primary font-weight-bold text-capitalize ml-auto'>
          Drilldown
        </Row>
        <Row className='h4 ml-auto'>
          <label style={{ width: '150px' }}>Toggle to Expand</label>
          <label className='custom-toggle'>
            <input
              type='checkbox'
              checked={expandCollapse.isChecked}
              onChange={e => handleExpandCollapse(e.target.checked, -1)}
            />
            <span className='custom-toggle-slider rounded-circle bg-teal border border-primary' />
          </label>
        </Row>

        <ReactEcharts
          echarts={echarts}
          option={getOption(drillDownData)}
          notMerge={true}
          lazyUpdate={true}
          style={{ height: expandCollapse.height }}
          theme={'theme_name'}
          // onChartReady={onChartReadyCallback}
          onEvents={onEvents}
          // {{
          //     click: params => onClick(params)
          // }}
          opts={{ renderer: 'canvas' }}
        />

        <div>
        </div>
      </div>
    ) : null:
      !traceabilitySoLoading && traceabilitySoErrors?
      <Error message={messageList.error} />:null
    }
      
    </>
  );
}
