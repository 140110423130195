import { api } from '../../axios-instance';
import { headers, backendRoutes, stringEncoder } from '../../shared/utility';
import * as actionTypes from './action-types';
import * as actions from './index';

export const getTraceabilitySOStart = _ => ({
  type: actionTypes.GET_TRACEABILITY_SO_START
});

export const getTraceabilitySOSuccess = traceability_so => ({
  type: actionTypes.GET_TRACEABILITY_SO_SUCCESS,
  traceability_so
});

export const getTraceabilitySOFail = (error, msg) => ({
  type: actionTypes.GET_TRACEABILITY_SO_FAIL,
  error,
  msg
});

export const getTraceabilitySO = so_number => {
  return dispatch => {
    if (so_number) {
      api
        .get(backendRoutes.getTraceabilitySO(so_number), headers())
        .then(response => {
          let soData = response.data.result.payload;
          const removeChild = (soData) =>
          {
            if(!soData.children.length)
            return soData;
            else
            {
              soData.children.map(child => {
                if(child.value === 'ICBL900441')
                {
                  child.children = [];
                  return child;
                }
                else
                return removeChild(child)
              })
              return soData;
            }
          }
          if(!localStorage.getItem('ICBL900441'))
          {
            soData = removeChild(soData)
          }
          dispatch(getTraceabilitySOSuccess(soData));
        })
        .catch(error => {
          dispatch(getTraceabilitySOFail(error, error.message));
        });
    } else dispatch(getTraceabilitySOSuccess());
  };
};

export const getAllSOStart = _ => ({
  type: actionTypes.GET_ALL_SO_START
});

export const getAllSOSuccess = allSO => ({
  type: actionTypes.GET_ALL_SO_SUCCESS,
  allSO
});

export const getAllSOFail = (error, msg) => ({
  type: actionTypes.GET_ALL_SO_FAIL,
  error,
  msg
});

export const breadCrumb = (name, id) => ({
  type: actionTypes.BREADCRUMB,
  name,
  id
});

export const getAllSO = () => {
  return dispatch => {
    dispatch(getAllSOStart());
    api
      .get(backendRoutes.getAllSO, headers())
      .then(response => {
        dispatch(getAllSOSuccess(response.data.result.payload));
        dispatch(actions.breadCrumbSuccess('Departments',null,0,0,undefined));
      })
      .catch(error => {
        dispatch(getAllSOFail(error, error.message));
      });
  };
};


export const getBalesByNumberStart = _ => ({
  type: actionTypes.GET_BALES_BY_NUMBER_START
});

export const getBalesByNumberSuccess = bales => ({
  type: actionTypes.GET_BALES_BY_NUMBER_SUCCESS,
  bales
});

export const getBalesByNumberFail = (error, msg) => ({
  type: actionTypes.GET_BALES_BY_NUMBER_FAIL,
  error,
  msg
});

export const getBalesByNumber = baleLotNumber => {
  return dispatch => {
    dispatch(getBalesByNumberStart())
    if (baleLotNumber) {
      api
        .get(backendRoutes.getBalesByNumber(stringEncoder(baleLotNumber)), headers())
        .then(response => {
          dispatch(getBalesByNumberSuccess(response.data.result.payload));
        })
        .catch(error => {
          dispatch(getBalesByNumberFail(error, error.message));
        });
    } else dispatch(getBalesByNumberSuccess());
  };
};

export const generateQrCodeStart = _ => ({
  type: actionTypes.GENERATE_QR_CODE_START
});

export const generateQrCodeSuccess = data => ({
  type: actionTypes.GENERATE_QR_CODE_SUCCESS,
  data
});

export const generateQrCodeFail = (error, msg) => ({
  type: actionTypes.GENERATE_QR_CODE_FAIL,
  error,
  msg
});

export const resetGenerateQrCode = () => ({
  type: actionTypes.RESET_GENERATE_QR_CODE
});


export const generateQrCode = (soLineItem, data) => {
  return dispatch => {
    dispatch(generateQrCodeStart())
    if (soLineItem) {
      api
        .post(backendRoutes.generateQrCode(soLineItem), { data }, headers())
        .then(response => {
          let { payload } = response.data.result;

          dispatch(generateQrCodeSuccess({soLineItem,payload}));
        })
        .catch(error => {
          dispatch(generateQrCodeFail(error, error.message));
          setTimeout(()=>{dispatch(resetGenerateQrCode())},5000)
        });
    } else dispatch(generateQrCodeSuccess());
  };
};