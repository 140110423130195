import * as actionTypes from '../actions/action-types';
import { updateObject, getFromLocalStorage } from '../../shared/utility';

const initialState = {
  success: null,
  loading: false,
  authRedirectPath: '/',
  email: null,
  token: null,
  expiry: null,
  role: 'user',
  errors: null,
  msg:null,
  availableRoutes: [],
  landingPage: '/auth/login'
};

const signInStart = (state, action) =>
  updateObject(state, {
    errors: null,
    loading: true
  });

const signInSuccess = (state, action) => {

  return updateObject(state, {
    email: action.email,
    token: action.token,
    errors: null,
    loading: false,
    expiry: action.expiry,
    success:true,
    msg: null,
    authRedirectPath: '/',
    role: action.role
  });
};

const signInFail = (state, action) => {
  return updateObject(state, {
    errors: true,
    msg: action.error,
    loading: false,
    expiry: null
  });
};
const resetMessages = (state, action) => {
  return updateObject(state, {
    msg: null,
    errors: null,
    success: null,
    loading: false
  });
};

const signUpStart = (state, action) => {
  return updateObject(state, {
    errors: null,
    loading: true,
    signup: false,
    user: null
  });
};

const signUpSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    signup: true,
    success: true,
    errors: false,
    msg: action.data.result.msg,
    authRedirectPath: '/auth/login'
  });
};

const signUpFail = (state, action) => {
  return updateObject(state, {
    success: false,
    errors: true,
    msg: action.msg + action.errors,
    loading: false,
    signup: false
  });
};

const forgotPasswordStart = (state, action) => {
  return updateObject(state, {
    errors: null,
    loading: true,
    success: null
  });
};

const forgotPasswordSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    success: true,
    errors: null,
    msg: action.data
  });
};

const forgotPasswordFail = (state, action) => {
  return updateObject(state, {
    errors: true,
    success: false,
    msg: action.error.message,
    loading: false 
  });
};

const checkResetPasswordTokenStart = (state, action) => {
  return updateObject(state, {
    errors: null,
    loading: true,
    success: null
  });
};

const checkResetPasswordTokenSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    success: true,
    errors: null,
    msg: null
  });
};

const checkResetPasswordTokenFail = (state, action) => {
  return updateObject(state, {
    errors: true,
    success: false,
    msg: action.error.msg,
    loading: false
  });
};

const setPasswordStart = (state, action) => {
  return updateObject(state, {
    errors: null,
    loading: true,
    success: null
  });
};

const setPasswordSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    success: true,
    errors: null,
    msg: null
  });
};

const setPasswordFail = (state, action) => {
  return updateObject(state, {
    errors: true,
    success: false,
    msg: action.error.msg,
    loading: false
  });
};

const setAuthRedirectPath = (state, action) => {
  let availableRoutes = [];
  let landingPage = '';
  switch (getFromLocalStorage('user_type')) {
    case 'Customer':
      availableRoutes = [
        {
          layout: '/admin/traceability',
          path: '/drilldown',
          name: 'Traceability'
        }
      ];
      landingPage = availableRoutes[0].layout + availableRoutes[0].path;
      break;
    case 'Owner':
      availableRoutes = [
        {
          layout: '/admin/departments',
          path: '/index',
          name: `Departments`
        },
        {
          layout: '/admin/traceability',
          path: '/drilldown',
          name: 'Traceability'
        }
      ];
      landingPage = availableRoutes[0].layout + availableRoutes[0].path;
      break;
    default:
      availableRoutes = [];
      landingPage = '/admin/';
  }

  return updateObject(state, {
    authRedirectPath: action.path,
    availableRoutes,
    landingPage
  });
};

const signOut = (state, action) => {
  return updateObject(state, {
    errors:null,
    sucess:null,
    loading:false,
    msg:null,
    token: null,
    email: null,
    expiry: null
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SIGNIN_START:
      return signInStart(state, action);
    case actionTypes.SIGNIN_SUCCESS:
      return signInSuccess(state, action);
    case actionTypes.SIGNIN_FAIL:
      return signInFail(state, action);

    case actionTypes.SIGNUP_START:
      return signUpStart(state, action);
    case actionTypes.SIGNUP_SUCCESS:
      return signUpSuccess(state, action);
    case actionTypes.SIGNUP_FAIL:
      return signUpFail(state, action);

    case actionTypes.FORGOT_PASSWORD_START:
      return forgotPasswordStart(state, action);
    case actionTypes.FORGOT_PASSWORD_SUCCESS:
      return forgotPasswordSuccess(state, action);
    case actionTypes.FORGOT_PASSWORD_FAIL:
      return forgotPasswordFail(state, action);

    case actionTypes.CHECK_RESET_PASSWORD_TOKEN_START:
      return checkResetPasswordTokenStart(state, action);
    case actionTypes.CHECK_RESET_PASSWORD_TOKEN_SUCCESS:
      return checkResetPasswordTokenSuccess(state, action);
    case actionTypes.CHECK_RESET_PASSWORD_TOKEN_FAIL:
      return checkResetPasswordTokenFail(state, action);

    case actionTypes.SET_PASSWORD_START:
      return setPasswordStart(state, action);
    case actionTypes.SET_PASSWORD_SUCCESS:
      return setPasswordSuccess(state, action);
    case actionTypes.SET_PASSWORD_FAIL:
      return setPasswordFail(state, action);

    case actionTypes.SIGNOUT:
      return signOut(state, action);
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);

    case actionTypes.RESET_MESSAGES:
      return resetMessages(state, action);
    default:
      return state;
  }
};

export default reducer;
