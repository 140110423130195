import React from 'react';
// import styled from 'styled-components'
import { Table } from 'reactstrap';
import { useTable, useSortBy, usePagination } from 'react-table';
import Paginate from '../Pagination/Paginate';
import Moment from 'react-moment';

// import makeData from './makeData';

function DataTable({columns, data, handleClick, handleQrClicked, viewDocument, pageNumberTracker, targetPageNumber}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: targetPageNumber?targetPageNumber:0 }
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      {/* <pre>
        <code>
          {JSON.stringify(
            {
              pageIndex,
              pageSize,
              pageCount,
              canNextPage,
              canPreviousPage,
            },
            null,
            2
          )}
        </code>
      </pre> */}
      <Table striped responsive hover {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                return (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} className="padding-narrow" >
                    {column.render('Header')}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <>
                            {' '}
                            <i className='fa fa-chevron-down'></i>
                          </>
                        ) : (
                          <>
                            {' '}
                            <i className='fa fa-chevron-up'></i>
                          </>
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {!page.length ? (
            <tr>
              <td className="padding-narrow" colSpan='100%'>
                <strong>Not Found</strong>
              </td>
            </tr>
          ) : null}
          {page.map((row, i) => {
            prepareRow(row);

            return (
              <tr
                id={`row-${i}`}
                {...row.getRowProps()}
                onClick={() => {
                  if (handleClick) handleClick(row.original);
                }}
                className={`${handleClick ? 'showpointer' : ''}`}
              >
                {row.cells.map((cell,index) => {
                  const regex = RegExp(
                    /\d{4}-\d{1,2}-\d{1,2}T\d{1,2}:\d{1,2}:\d{1,2}[Z]?/g
                  );
                  if (regex.test(cell.value))
                    return (
                      <td className="padding-narrow" {...cell.getCellProps()} key={index}>
                        <Moment format='DD/MM/YYYY'>{cell.value}</Moment>
                      </td>
                    );
                  else if (
                    cell.value &&
                    (cell.value.toLowerCase() === 'pending' ||
                      cell.value.toLowerCase() === 'approved')
                  )
                    return (
                      <td className="padding-narrow text-capitalize" key={index}>
                        {cell.value.toLowerCase() === 'pending' ? (
                          <>
                            <span className='text-danger pr-2'>&bull;</span>
                            {cell.value}
                          </>
                        ) : (
                          <>
                            <span className='text-success pr-2'>&bull;</span>
                            {cell.value}
                          </>
                        )}
                      </td>
                    );
                  else if (cell.column.id.includes('ACTION')) {
                    switch (cell.column.id) {
                      case 'TRACEABILITY_ACTION':
                        return (
                          <td className="padding-narrow px-1"
                            onClick={e => handleQrClicked(e, row.original)} key={index}
                          >
                            <span
                              className={`text-primary showpointer ${
                                row.original.has_qr_code
                                  ? 'text-dark'
                                  : 'text-primary'
                              } font-weight-bold border-0`}
                            >
                              {row.original.has_qr_code
                                ? 'View QR'
                                : 'Generate QR'}
                            </span>
                          </td>
                        );
                      case 'CERTIFICATES_ACTION':
                        return (
                          <td className="padding-narrow text-titlecase" key={index}>
                            <span
                              className='text-primary showpointer'
                              onClick={() => viewDocument(row.original)}
                            >
                              View
                            </span>
                          </td>
                        );
                      default:
                        return <td className="padding-narrow" key={index}>N.A.</td>;
                    }
                  } else
                    return (
                      <td className="padding-narrow" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {data.length > 10 ? (
        <Paginate
          currentPage={parseInt(pageIndex) + 1}
          paginate={pageNumber => {
            if(pageNumberTracker) pageNumberTracker(pageNumber - 1)
            gotoPage(pageNumber - 1);

          }}
          targetArrayLength={data.length}
        />
      ) : null}

      {/* <div className='pagination'>
        <button
          className='btn btn-sm-primary'
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <i className='fa fa-chevron-left m-0' />
          <i className='fa fa-chevron-left m-0' />
        </button>{' '}
        <button
          className='btn btn-sm-primary'
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <i className='fa fa-chevron-left m-0' />
        </button>{' '}
        <button
          className='btn btn-sm-primary'
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          <i className='fa fa-chevron-right m-0' />
        </button>{' '}
        <button
          className='btn btn-sm-primary'
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          <i className='fa fa-chevron-right m-0' />
          <i className='fa fa-chevron-right m-0' />
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <div className='form-group inline'>
          <label>| Go to page: </label>
          <input
            className='form-control'
            type='number'
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: '100px' }}
          />
        </div>
        <div className='form-group'>
          <select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div> */}
    </>
  );
}

function App(props) {

  let {data, handleClick, handleQrClicked, viewDocument, pageNumberTracker, pageNumber} = props;
  const columns = React.useMemo(() => props.headers, [props.headers]);

  // const data = React.useMemo(() => props.data, []);
  return (
    <DataTable
      columns={columns}
      data={data}
      handleClick={handleClick}
      handleQrClicked={handleQrClicked}
      viewDocument={viewDocument}
      pageNumberTracker={pageNumberTracker}
      targetPageNumber = {pageNumber}
    />
  );
}

export default App;
