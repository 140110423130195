import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Col,
  Container,
  Row,
  DropdownItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CustomInput,
  FormGroup,
  Input,
  Label,
  Progress,
  Table,
} from 'reactstrap';
import Error from '../../shared/Error/Error';
import SearchAndFilter from '../../shared/Filters/SearchAndFilter';
import getAssetImage from '../../shared/ImageAssets';
import Loader from '../../shared/Loader/Loader';
import Tables from '../../shared/Tables/Tables';
import Validators from '../../shared/Validations';
import {
  eventAction,
  eventTracker,
  messageList,
  stringEncoder,
} from '../../shared/utility';
import * as actions from '../../store/actions/index';
import './DepartmentSummary.css';
class DepartmentSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table_data: [],
      batchNamesArray: [],
      batchesArray: [],
      filteredDisplayList: [],
      unfilteredDisplayList: [],
      selectedTab: 0,
      pageNumber:0,
      openFilter: false,
      resetFields: false,
      dropdownOpen: false,
      openUploadModal: false,
      file: null,
      imagePreviewUrl: null,
      uploadFileName: '',
      documentDescription: '',
      uploadFileNameTouched: false,
      uploadFileNameOkay: false,
      enableUpload: false,
      isWorking: false
    };
    this.validators = {
      uploadFileName: {
        validators: value => Validators.fieldNeeded(value),
        errorMessage: 'File not selected'
      },
    };
  }

  componentDidMount() {
    this.props.onGetDepartmentSummary(this.props.match.params.department_id);
  }

  componentDidUpdate() {
    if(this.state.isWorking &&
      !this.props.internalBatchesLoading &&
      !this.props.internalBatchesError)
      {
        this.toggleUploadModal();
      }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.departmentSummary &&
      Object.keys(nextProps.departmentSummary).length &&
      nextProps.departmentSummary.batches !== prevState.table_data
    ) {
      let separatedBatches = {};
      if (nextProps.departmentSummary.batches.length)
        separatedBatches = batchSeparator(nextProps.departmentSummary.batches);
      return {
        unfilteredDisplayList: separatedBatches.batchesArray
          ? separatedBatches.batchesArray[0]
          : [],
        filteredDisplayList: separatedBatches.batchesArray
          ? separatedBatches.batchesArray[0]
          : [],
        table_data: nextProps.departmentSummary.batches,
        batchesArray: separatedBatches.batchesArray
          ? separatedBatches.batchesArray
          : [],
        batchNamesArray: separatedBatches.batchNamesArray
          ? separatedBatches.batchNamesArray
          : [],
      };
    } else return {};

    function batchSeparator(targetArray) {
      //get names of all batches that will be used to display as horizontal navbar tabs in UI
      let batchNamesArray = targetArray.length
        ? targetArray.reduce((final, initial) => {
            if (final.indexOf(initial.stage) === -1) {
              final.push(initial.stage);
              return final;
            } else return final;
          }, [])
        : [];

      //create an array that will be of the same size as the number of batch names & populate with data of that batch.
      let batchesArray = new Array(batchNamesArray.length);
      targetArray.map((item) => {
        for (let i = 0; i < batchNamesArray.length; i++) {
          if (item.stage === batchNamesArray[i]) {
            if (batchesArray[i] && batchesArray[i].length) {
              let tempArr = batchesArray[i];
              tempArr.push(item);
              batchesArray[i] = tempArr;
            } else batchesArray[i] = [item];
          }
        }
        return null;
      });
      return { batchNamesArray, batchesArray };
    }
  }

  exportDeptData = () => {
    eventTracker(
      `${this.props.departmentSummary.name} - ${
        this.state.batchNamesArray[this.state.selectedTab]
      }`,
      eventAction.exportClicked
    );
    this.props.onGetBatchReport(
      this.state.batchNamesArray[this.state.selectedTab]
    );
  };

  uploadBatches = () => {
    this.setState({openUploadModal : true})
  }

  handleBatchList = (index = this.state.selectedTab) => {
    this.setState({
      selectedTab: index,
      unfilteredDisplayList: this.state.batchesArray[index],
      filteredDisplayList: this.state.batchesArray[index],
      resetFields: true,
    });
  };

  resetFieldsComplete = () => {
    this.setState({ resetFields: false });
  };

  tableHeaders = () => {
    return [
      {
        Header: this.state.batchNamesArray[this.state.selectedTab]
          ? this.state.batchNamesArray[this.state.selectedTab]
          : 'Batches',
        accessor: 'batch_number',
      },
      { Header: 'Created At', accessor: 'creation_date' },
      { Header: 'Approval Status', accessor: 'approval_status' },
    ];
  };

  handleClick = (data) => {
    this.props.history.push(
      encodeURI(
        `/admin/departments/${
          this.props.match.params.department_id
        }/batches/${stringEncoder(data['batch_number'])}`
      )
    );
  };

  pageNumberTracker = (pageNumber) => {
    this.setState({pageNumber})
  }

  updateDisplayList = (searchFilteredList) => {
    this.setState({pageNumber:0})
    this.setState({ filteredDisplayList: searchFilteredList });
  };

  toggleUploadModal = () => {
    this.setState({ openUploadModal: !this.state.openUploadModal });
    this.resetForm()
    this.props.onResetBatchesAlerts()
  };

  resetForm = () =>{
    this.setState({
      isWorking: false,
      uploadFileName: '',
      uploadFileNameOkay: false,
      uploadFileNameTouched: false,
      enableUpload: false
    })
  }

  getFile = e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.setState(
        {
          file: file,
          imagePreviewUrl: reader.result,
          uploadFileName: file.name,
          uploadFileNameTouched: true,
          uploadFileNameOkay: this.validators.uploadFileName.validators(
            file.name
          )
        },
        () =>
          this.setState({
            enableUpload: this.handleEnableUpload(),
          })
      );
    };
  };

  inputChangeHandler = (event, controlName) => {
    this.setState(
      {
        [controlName]: event.target.value,
        [`${controlName}Touched`]: true,
        [`${controlName}Okay`]: this.validators[controlName].validators(
          event.target.value
        )
      },
      () =>
        this.setState({
          enableUpload: this.handleEnableUpload(),
        })
    );
  };

  handleEnableUpload = () => {
    return this.state.uploadFileNameOkay &&
      this.state.uploadFileNameTouched
  };

  handleUpload = () =>{
    this.setState({
      isWorking: true
    })
    if(this.handleEnableUpload)
    {
      let payload = {
        file : {
          file_name: this.state.uploadFileName,
          file_base64_encoded: this.state.imagePreviewUrl.split(
            'base64,'
          )[1]
        },
      product: 'Terry'
      }
      this.props.onInternalBatches(payload ,this.state.batchNamesArray[this.state.selectedTab])
    }
  }
  
  render() {
    let { batchNamesArray , dropdownOpen } = this.state;
    let { departmentSummary, deptLoading, deptSuccess, deptError } = this.props;

    return (
      <React.Fragment>
        <Container fluid className='pb-5'>
          {deptLoading ? (
            <Loader />
          ) : !deptLoading && deptSuccess ? (
            <>
              <Row>
                <Col lg='9' md='9' sm='12' xs='12'>
                  <div className='float-left mb-2 '>
                    {departmentSummary.name ? (
                      <img
                        src={getAssetImage(
                          departmentSummary.name
                            .toLowerCase()
                            .replace(/ /g, '_')
                        )}
                        alt='dept'
                        width='50px'
                      />
                    ) : null}
                    <span className='h1 ml-4 text-primary font-weight-bold text-capitalize text-left underline border-primary'>                      {departmentSummary.name
                        ? `Department: ${departmentSummary.name}`
                        : ''}
                    </span>
                  </div>
                </Col>
                <Col lg='9' md='9' sm='12' xs='12'>
                  <span className='h4 mb-2 text-default text-capitalize float-left'>
                    {departmentSummary.description
                      ? `Description: ${departmentSummary.description}`
                      : ''}
                  </span>
                </Col>
              </Row>
              <div className='pb-4'></div>
              <SearchAndFilter
                resetAll={this.handleBatchList}
                searchArray={this.state.unfilteredDisplayList}
                searchColumn={'batch_number'}
                updateDisplayList={this.updateDisplayList}
                placeholderText={
                  this.state.batchNamesArray[this.state.selectedTab]
                }
                resetFields={this.state.resetFields}
                resetFieldsComplete={this.resetFieldsComplete}
                selectSearchColumn={'approval_status'}
                dateSearchColumn={'creation_date'}
              />
              <div className='pb-5'></div>
              <Row>
                <Col lg='12' md='12' xl='9'>
                  {/* {batchNamesArray.length > 1? */}
                  <ul className='nav nav-tabs'>
                    {batchNamesArray.map((name, i) => (
                      <li className='nav-item bg-white' key={i}>
                      <span
                          className={`nav-link showpointer bg-white px-4 text-uppercase h3 mb-0 font-weight-normal ${
                            this.state.selectedTab === i
                              ? 'font-weight-bold text-primary set-border'
                              : 'pt-2'
                          } `}
                          onClick={() => {
                            this.handleBatchList(i);
                          }}
                        >
                          <span>{name}</span>
                        </span>
                      </li>
                    ))}
                  </ul>
                  {/* :null} */}

                  <div className='container-fluid bg-white rounded shadow'>
                    <div className='py-4'>
                      <Row>
                        <Col className='pr-0'>
                          <Dropdown
                            className='float-right'
                            isOpen={dropdownOpen}
                            toggle={() => {
                              this.setState({
                                dropdownOpen: !dropdownOpen,
                              });
                            }}
                          >
                            <DropdownToggle color='white' className='btn-link'>
                              <i
                                className='fa fa-ellipsis-v text-success'
                                aria-hidden='true'
                              ></i>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => this.uploadBatches()}
                              >
                                <img
                                  src={getAssetImage('add_batches')}
                                  width='10%'
                                  height='5%'
                                  className='mr-3'
                                />
                                Add New Batches
                              </DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem
                                onClick={() => this.exportDeptData()}
                              >
                                <img
                                  src={getAssetImage('export_data')}
                                  width='10%'
                                  height='5%'
                                  className='mr-3'
                                />
                                Export Data
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </Col>
                      </Row>
                    </div>
                    <Row>
                      <Tables
                        data={this.state.filteredDisplayList}
                        headers={this.tableHeaders()}
                        handleClick={this.handleClick}
                        pageNumber={this.state.pageNumber}
                        pageNumberTracker={this.pageNumberTracker}
                      />
                      {/* <Table striped responsive hover>
                        <thead>
                          <tr>
                            <th>
                              {this.state.batchNamesArray[
                                this.state.selectedTab
                              ]
                                ? this.state.batchNamesArray[
                                    this.state.selectedTab
                                  ]
                                : 'Batches'}
                            </th>
                            <th>Created At</th>
                            <th>Approval Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentBatches.length ? (
                            currentBatches.map((data, index) => (
                              <tr
                                key={index}
                                className='showpointer'
                                onClick={() => this.handleClick(data)}
                              >
                                <td>{data.batch_number}</td>
                                <td>
                                  <Moment format='DD/MM/YYYY'>
                                    {data.creation_date}
                                  </Moment>
                                </td>
                                <td className='text-capitalize'>
                                  {data.approval_status.toLowerCase() ===
                                  'pending' ? (
                                    <>
                                      <span className='text-danger pr-2'>
                                        &bull;
                                      </span>
                                      {data.approval_status}
                                    </>
                                  ) : (
                                    <>
                                      <span className='text-success pr-2'>
                                        &bull;
                                      </span>
                                      {data.approval_status}
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan='3'>
                                <strong>Not Found</strong>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      {this.state.pageCount > 1 ? (
                        <Paginate
                          currentPage={parseInt(this.state.currentPage)}
                          paginate={this.paginate}
                          targetArrayLength={
                            this.state.filteredDisplayList.length
                          }
                        />
                      ) : null} */}
                    </Row>
                  </div>
                </Col>
                <Modal
                  size='lg'
                  isOpen={this.state.openUploadModal}
                  toggle={this.toggleUploadModal}
                  backdrop={'static'}
                >
                  <ModalHeader toggle={this.toggleUploadModal}>
                    <h2>Add New Batches</h2>
                  </ModalHeader>
                  <ModalBody>
                    <div className='mt-2'>
                      <FormGroup>
                        <Row>
                          <Col className='col-md-6 mt-2'>
                            <Label>Department</Label>
                            <Input
                              className='border theme-border-color rounded p-2'
                              autoComplete='off'
                              placeholder='Enter Department'
                              value={departmentSummary.description}
                              disabled={true}
                            />
                          </Col>
                          <Col className='col-md-6 mt-2'>
                            <Label>Batch Type</Label>
                            <Input
                              className='border theme-border-color rounded p-2'
                              autoComplete='off'
                              placeholder='Enter Batch Type'
                              value={batchNamesArray[this.state.selectedTab]}
                              disabled={true}
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <CustomInput
                          className='w-100 text-truncate mt-4'
                          type='file'
                          id='exampleCustomFileBrowser'
                          label={
                            this.props.fileDetails &&
                            this.props.fileDetails.uploadFileName
                              ? this.props.fileDetails.uploadFileName
                              : this.state.uploadFileName
                              ? this.state.uploadFileName
                              : 'Choose File'
                          }
                          name='customFile'
                          onChange={this.getFile}
                        />
                        {this.state.uploadFileNameTouched &&
                        !this.state.uploadFileNameOkay ? (
                          <span className='text-danger'>
                            <small>
                              {this.validators.uploadFileName.errorMessage}
                            </small>
                          </span>
                        ) : null}
                      </FormGroup>
                    </div>
                    <Row>
                      {this.props.internalBatchesLoading ? (
                        <div className='w-100'>
                        <Progress animated bar value='100'>
                          <strong>
                            Please Wait...
                          </strong>
                        </Progress>
                      </div>
                      ) : null}
                      </Row>
                      <Container className='mt-2'>
                      {this.props.internalBatchesError &&
                      !this.props.internalBatchesLoading ? (
                        <Error message={`Error! ${this.props.internalBatchesMsg}`} />
                      ) : null}
                      </Container>
                    <Row>
                      {this.props.internalBatchesSuccess &&
                      this.props.duplicateBatches &&
                      this.props.duplicateBatches.length ? (
                        <Table>
                          <thead>
                            <tr
                              style={{
                                color: 'red',
                                fontWeight: 'bold',
                                fontSize: '20',
                              }}
                            >
                              <th> Following Batches are not updated.</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props.duplicateBatches.map((row) => (
                              <tr key={row[1]}>
                                <th
                                  style={{ color: 'red' }}
                                  scope='row'
                                  component='th'
                                >
                                  {row[0]}
                                </th>
                                <th style={{ color: 'red' }}>{row[1]}</th>
                                <th style={{ color: 'red' }}>{row[2]}</th>
                                <th style={{ color: 'red' }}>{row[3]}</th>
                                <th style={{ color: 'red' }}>{row[4]}</th>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      ) : this.props.internalBatchesSuccess &&
                        this.state.isWorking ? (
                        <span style={{ color: 'green' }} className='ml-9'>
                          {this.props.internalBatchesMsg}
                        </span>
                      ) : null}
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Row>
                      <Button
                        className='btn-border-style-outline mr-3'
                        color='primary'
                        onClick={() => this.handleUpload()}
                        disabled={!this.state.enableUpload}
                        hidden={this.props.internalBatchesLoading}
                      >
                        Upload File
                      </Button>{' '}
                    </Row>
                  </ModalFooter>
                </Modal>
              </Row>
            </>
          ) : !deptLoading && deptError ? (
            <Error message={messageList.error} />
          ) : null}
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  departmentSummary: state.departments.departmentById,
  deptLoading: state.departments.loading,
  deptSuccess: state.departments.success,
  deptError: state.departments.errors,
  deptMsg: state.departments.msg,
  batchFileSuccess: state.departments.exportReportSuccess,
  batchFileLoading: state.departments.exportReportLoading,
  batchFileError: state.departments.exportReportErrors,
  batchFileMsg: state.departments.exportReportMsg,
  batchReport: state.departments.report,
  internalBatchesSuccess: state.internalBatches.internalBatchesSuccess,
  internalBatchesLoading: state.internalBatches.internalBatchesLoading,
  internalBatchesError: state.internalBatches.internalBatchesError,
  internalBatchesMsg: state.internalBatches.internalBatchesMsg,
  duplicateBatches: state.internalBatches.duplicateBatches
});
const mapDispatchToProps = (dispatch) => ({
  onGetDepartmentSummary: (id) => dispatch(actions.getDepartmentById(id)),
  onGetBatchReport: (stageName) =>
    dispatch(actions.exportBatchListFile(stageName)),
  onInternalBatches: (payload ,stageName) =>
    dispatch(actions.internalBatches(payload ,stageName)), 
  onResetBatchesAlerts : () => 
    dispatch(actions.resetBatchesAlerts())
 
});

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentSummary);
