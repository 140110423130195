import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import './App.css';
import "./assets/scss/argon-dashboard-react.scss";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/vendor/nucleo/css/nucleo.css";
import AdminLayout from './layouts/Admin';
import AuthLayout from './layouts/Auth';
import Bales from './views/traceability/Bales'
import { appTitle, titleIcon } from './themebuilder/theme';
import TransferBatchesData from './views/departments/TransferBatchesData';

function App() {
  document.title = appTitle;
  titleIcon(); 
  return (
    <div className="App">
      <Switch>
        <Route path="/admin" render={props => <AdminLayout {...props} />} />
        <Route path="/auth/login" component={() => <AuthLayout layout="/auth" path='/login' />} />
        <Route path="/auth/forgotpassword" component={() => <AuthLayout layout="/auth" path='/forgotpassword' />} />
        <Route path="/auth/resetpassword" component={() => <AuthLayout layout="/auth" path='/resetpassword' />} />
        <Route path="/auth/externalbatches" component={() => <AuthLayout layout="/auth" path='/externalbatches' />} />
        <Route path="/bales/:id" component={()=> <Bales />} />
        <Route path="/auth/register" component={() => <AuthLayout layout="/auth" path='/register' />} />
        <Redirect from="*" to="/admin" exact />
      </Switch>
    </div>
  );


}

export default App
