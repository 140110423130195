import { updateObject } from '../../shared/utility';
import * as actionTypes from '../actions/action-types';

export const initialState = {
    downloadLink: null,
    success:null,
    errors: null,
    msg: null,
    loading: false
};

const printQRPDFStart = (state, action) => updateObject(state, {
    loading: true,
    success:null,
    errors: null,
    msg: null,
})

const printQRPDFSuccess = (state, action) => updateObject(state, {
    loading: false,
    success:true,
    errors: null,
    msg: null,
})

const printQRPDFFail = (state, action) => updateObject(state, {
    loading: false,
    success:false,
    errors: true,
    msg: null,
})

const printQRPDFReset = (state, action) => updateObject(state, {
    success:null,
    errors: null,
    msg: null,
    loading: false
})

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PRINT_QR_PDF_START:
            return printQRPDFStart(state, action)
        case actionTypes.PRINT_QR_PDF_SUCCESS:
            return printQRPDFSuccess(state, action)
        case actionTypes.PRINT_QR_PDF_FAIL:
            return printQRPDFFail(state, action)
            case actionTypes.PRINT_QR_PDF_RESET:
                return printQRPDFReset(state, action)

        default:
            return state;
    }
};
