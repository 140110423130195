import React from 'react';
import './Loader.css';
export default function Loader(props) {
  return (
    <React.Fragment>
      <div className='row'>
        <span className='h2 text-default text-capitalize'>
          {props.text || 'Loading'}
        </span>{' '}
        <div
          className=' ml-3 dot-pulse d-inline'
          style={{ marginTop: '10px' }}
        ></div>
      </div>
    </React.Fragment>
  );
}
