import * as actionTypes from '../actions/action-types';
import { updateObject } from '../../shared/utility';

const initialState = {
  success: null,
  loading: false,
  error: null,
  msg: null,
  checkLoading: false,
  checkError: null,
  checkMsg: null,
  checkSuccess: null,
  transferBatchesDetails: null,
  requestBatchesSuccess: null,
  requestBatchesLoading: false,
  requestBatchesError: null,
  requestBatchesMsg: null,
};

const externlaBatchesStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};
const externlaBatchesSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    success: true,
  });
};

const externlaBatchesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    msg: action.msg,
    loading: false,
  });
};

const resetTransferMessages = (state, action) => {
  return updateObject(state, {
    msg: null,
    errors: null,
    success: null,
    loading: false
  });
};

const checkTransferTokenStart = (state, action) => {
  return updateObject(state, {
    checkError: null,
    checkLoading: true,
    checkSuccess: null
  });
};

const checkTransferTokenSuccess = (state, action) => {
  return updateObject(state, {
    checkLoading: false,
    checkSuccess: true,
    checkError: null,
    checkMsg: null,
    transferBatchesDetails: action.data
  });
};

const checkTransferTokenFail = (state, action) => {
  return updateObject(state, {
    checkError: true,
    checkSuccess: false,
    checkMsg: action.error.msg,
    checkLoading: false
  });
};

const requestBatchesStart = (state, action) => {
  return updateObject(state, {
    requestBatchesError: null,
    requestBatchesLoading: true,
  });
};
const requestBatchesSuccess = (state, action) => {
  return updateObject(state, {
    requestBatchesError: null,
    requestBatchesLoading: false,
    requestBatchesSuccess: true,
    requestBatchesMsg: action.batches.msg,
  });
};

const requestBatchesFail = (state, action) => {
  return updateObject(state, {
    requestBatchesError: action.error,
    requestBatchesMsg: action.msg,
    requestBatchesLoading: false,
  });
};

const resetRequestBatchesMessages = (state, action) => {
  return updateObject(state, {
    requestBatchesMsg: null,
    requestBatchesError: null,
    requestBatchesSuccess: null,
    requestBatchesLoading: false
  });
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EXTERNAL_BATCHES_START:
      return externlaBatchesStart(state, action);
    case actionTypes.EXTERNAL_BATCHES_SUCCESS:
      return externlaBatchesSuccess(state, action);
    case actionTypes.EXTERNAL_BATCHES_FAIL:
      return externlaBatchesFail(state, action);
    case actionTypes.CHECK_TRANSFER_TOKEN_START:
      return checkTransferTokenStart(state, action);
    case actionTypes.CHECK_TRANSFER_TOKEN_SUCCESS:
      return checkTransferTokenSuccess(state, action);
    case actionTypes.CHECK_TRANSFER_TOKEN_FAIL:
      return checkTransferTokenFail(state, action);
    case actionTypes.RESET_TRANSFER_BATCHES_MESSAGES:
      return resetTransferMessages(state, action);
    case actionTypes.REQUEST_BATCHES_START:
      return requestBatchesStart(state, action);
    case actionTypes.REQUEST_BATCHES_SUCCESS:
      return requestBatchesSuccess(state, action);
    case actionTypes.REQUEST_BATCHES_FAIL:
      return requestBatchesFail(state, action);
    case actionTypes.RESET_REQUEST_BATCHES_MESSAGES:
      return resetRequestBatchesMessages(state, action);

    default:
      return state;
  }
};
