import { api } from '../../axios-instance';
import { backendRoutes, headers } from '../../shared/utility';
import * as actionTypes from './action-types';
import {addNewActivitySuccess} from './batches';

export const internalBatchesStart = _ => ({
  type: actionTypes.INTERNAL_BATCHES_START
});

export const internalBatchesSuccess = batches => ({
  type: actionTypes.INTERNAL_BATCHES_SUCCESS,
  batches
});

export const internalBatchesFail = (error, msg) => ({
  type: actionTypes.INTERNAL_BATCHES_FAIL,
  error,
  msg
});

export const resetAddNewBatchesMessages = () => ({
  type: actionTypes.RESET_ADD_NEW_BATCHES_MESSAGES
});

export const resetBatchesAlerts = (timer = 3000) => {
  return dispatch => {
    setTimeout(() => {
      dispatch(resetAddNewBatchesMessages());
    }, timer);
  };
}

export const internalBatches = (payload, stageName) => {
  return dispatch => {
    dispatch(internalBatchesStart());
    api
      .post(backendRoutes.addInternalBatches(stageName), payload, headers())
      .then(response => {
        dispatch(internalBatchesSuccess(response.data.result.payload));
        dispatch(addNewActivitySuccess(response.data.result.payload))
        dispatch(resetBatchesAlerts());
      })
      .catch(error => {
        dispatch(internalBatchesFail(error, error.message));
      });
  };
};
