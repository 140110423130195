// SignIn
export const SIGNIN_START = "SIGNIN_START";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAIL = "SIGNIN_FAIL";

// Signup
export const SIGNUP_START = "SIGNUP_START";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

//Signout
export const SIGNOUT = "SIGNOUT";
export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";

//Init
export const INIT_START = "INIT_START";
export const INIT_SUCCESS = "INIT_SUCCESS";
export const INIT_FAIL = "INIT_FAIL";


//get departmant summary by id
export const GET_DEPARTMENT_BY_ID_START = "GET_DEPARTMENT_BY_ID_START";
export const GET_DEPARTMENT_BY_ID_SUCCESS = "GET_DEPARTMENT_BY_ID_SUCCESS";
export const GET_DEPARTMENT_BY_ID_FAIL = "GET_DEPARTMENT_BY_ID_FAIL";

//get batch list from dept
export const EXPORT_BATCH_LIST_FILE_START = "EXPORT_BATCH_LIST_FILE_START";
export const EXPORT_BATCH_LIST_FILE_SUCCESS = "EXPORT_BATCH_LIST_FILE_SUCCESS";
export const EXPORT_BATCH_LIST_FILE_FAIL = "EXPORT_BATCH_LIST_FILE_FAIL";

//get batch summary by id
export const GET_BATCH_BY_ID_START = "GET_BATCH_BY_ID_START";
export const GET_BATCH_BY_ID_SUCCESS = "GET_BATCH_BY_ID_SUCCESS";
export const GET_BATCH_BY_ID_FAIL = "GET_BATCH_BY_ID_FAIL";

//add new activity in batch
export const ADD_NEW_ACTIVITY_START = "ADD_NEW_ACTIVITY_START";
export const ADD_NEW_ACTIVITY_SUCCESS = "ADD_NEW_ACTIVITY_SUCCESS";
export const ADD_NEW_ACTIVITY_FAIL = "ADD_NEW_ACTIVITY_FAIL";

//get so to show in drilldown
export const GET_TRACEABILITY_SO_START = "GET_TRACEABILITY_SO_START";
export const GET_TRACEABILITY_SO_SUCCESS = "GET_TRACEABILITY_SO_SUCCESS";
export const GET_TRACEABILITY_SO_FAIL = "GET_TRACEABILITY_SO_FAIL";

//get so to show in drilldown
export const GENERATE_QR_CODE_START = "GENERATE_QR_CODE_START";
export const GENERATE_QR_CODE_SUCCESS = "GENERATE_QR_CODE_SUCCESS";
export const GENERATE_QR_CODE_FAIL = "GENERATE_QR_CODE_FAIL";
export const RESET_GENERATE_QR_CODE = "RESET_GENERATE_QR_CODE";

//approve batch by number
export const APPROVE_BATCH_BY_NUMBER_START = "APPROVE_BATCH_BY_NUMBER_START";
export const APPROVE_BATCH_BY_NUMBER_SUCCESS = "APPROVE_BATCH_BY_NUMBER_SUCCESS";
export const APPROVE_BATCH_BY_NUMBER_FAIL = "APPROVE_BATCH_BY_NUMBER_FAIL";

//get all SOs
export const GET_ALL_SO_START = "GET_ALL_SO_START";
export const GET_ALL_SO_SUCCESS = "GET_ALL_SO_SUCCESS";
export const GET_ALL_SO_FAIL = "GET_ALL_SO_FAIL";

//get Bales by number
export const GET_BALES_BY_NUMBER_START = "GET_BALES_BY_NUMBER_START";
export const GET_BALES_BY_NUMBER_SUCCESS = "GET_BALES_BY_NUMBER_SUCCESS";
export const GET_BALES_BY_NUMBER_FAIL = "GET_BALES_BY_NUMBER_FAIL";

//forgotpassword
export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

//check token validity
export const CHECK_RESET_PASSWORD_TOKEN_START = "CHECK_RESET_PASSWORD_TOKEN_START";
export const CHECK_RESET_PASSWORD_TOKEN_SUCCESS = "CHECK_RESET_PASSWORD_TOKEN_SUCCESS";
export const CHECK_RESET_PASSWORD_TOKEN_FAIL = "CHECK_RESET_PASSWORD_TOKEN_FAIL";

//reset password
export const SET_PASSWORD_START = "SET_PASSWORD_START";
export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS";
export const SET_PASSWORD_FAIL = "SET_PASSWORD_FAIL";

//check token validity
export const GET_CERTIFICATE_DOCUMENTS_START = "GET_CERTIFICATE_DOCUMENTS_START";
export const GET_CERTIFICATE_DOCUMENTS_SUCCESS = "GET_CERTIFICATE_DOCUMENTS_SUCCESS";
export const GET_CERTIFICATE_DOCUMENTS_FAIL = "GET_CERTIFICATE_DOCUMENTS_FAIL";

//create users
export const CREATE_USER_START = "CREATE_USER_START";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAIL = "CREATE_USER_FAIL";

//get list of users
export const GET_USERS_START = "GET_USERS_START";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";

//update user
export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const EXTERNAL_BATCHES_START = "EXTERNAL_BATCHES_START";
export const EXTERNAL_BATCHES_SUCCESS = "EXTERNAL_BATCHES_SUCCESS";
export const EXTERNAL_BATCHES_FAIL = "EXTERNAL_BATCHES_FAIL";

export const INTERNAL_BATCHES_START = "INTERNAL_BATCHES_START";
export const INTERNAL_BATCHES_SUCCESS = "INTERNAL_BATCHES_SUCCESS";
export const INTERNAL_BATCHES_FAIL = "INTERNAL_BATCHES_FAIL";

export const REQUEST_BATCHES_START = "REQUEST_BATCHES_START";
export const REQUEST_BATCHES_SUCCESS = "REQUEST_BATCHES_SUCCESS";
export const REQUEST_BATCHES_FAIL = "REQUEST_BATCHES_FAIL";

export const CHECK_TRANSFER_TOKEN_START = "CHECK_TRANSFER_TOKEN_START";
export const CHECK_TRANSFER_TOKEN_SUCCESS = "CHECK_TRANSFER_TOKEN_SUCCESS";
export const CHECK_TRANSFER_TOKEN_FAIL = "CHECK_TRANSFER_TOKEN_FAIL";

export const RESET_MESSAGES = "RESET_MESSAGES";

export const BREADCRUMB = "BREADCRUMB";

export const PRINT_QR_PDF_START = "PRINT_QR_PDF_START"
export const PRINT_QR_PDF_SUCCESS = "PRINT_QR_PDF_SUCCESS"
export const PRINT_QR_PDF_FAIL = "PRINT_QR_PDF_FAIL"
export const PRINT_QR_PDF_RESET = "PRINT_QR_PDF_RESET"

export const RESET_ADD_NEW_BATCHES_MESSAGES = "RESET_ADD_NEW_BATCHES_MESSAGES";

export const RESET_REQUEST_BATCHES_MESSAGES = "RESET_REQUEST_BATCHES_MESSAGES";

export const RESET_TRANSFER_BATCHES_MESSAGES = "RESET_TRANSFER_BATCHES_MESSAGES";



