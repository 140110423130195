import * as actionTypes from './action-types';
import { api } from '../../axios-instance';
import {
  headers,
  backendRoutes
} from '../../shared/utility';
import {breadCrumbSuccess} from './departments';

export const getCertificateDocumentsStart = _ => ({
  type: actionTypes.GET_CERTIFICATE_DOCUMENTS_START
});

export const getCertificateDocumentsSuccess = documents => ({
  type: actionTypes.GET_CERTIFICATE_DOCUMENTS_SUCCESS,
  documents
});

export const getCertificateDocumentsFail = (error, msg) => ({
  type: actionTypes.GET_CERTIFICATE_DOCUMENTS_FAIL,
  error,
  msg
});


export const getCertificateDocuments = () => {
  return dispatch => {
    dispatch(getCertificateDocumentsStart());
    api
      .get(backendRoutes.getDocuments, headers())
      .then(response => {
        let { payload } = response.data.result;
        dispatch(getCertificateDocumentsSuccess(payload));
        dispatch(breadCrumbSuccess('Departments', null, 0, 0, undefined));
      })
      .catch(error => {
        dispatch(getCertificateDocumentsFail(error, error.message));
        dispatch(breadCrumbSuccess('Departments', null, 0, 0, undefined));
      });
  };
};
