import React from 'react'

function SystemSettings() {
    return (
        <div>
            <h3>SystemSettings</h3>
        </div>
    )
}

export default SystemSettings
