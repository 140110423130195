import defaultIcon from '../assets/theme/icons/defaultIcon.ico'
import appleIcon from '../assets/theme/icons/appleIcon.ico'

export const appTitle = "Credible Core";

export const titleIcon = () => {
    let icon = document.createElement("link");
    let aIcon = document.createElement("link");
    const iconSetter = (link, iconFile) => {
        link.setAttribute('rel','icon')
        link.setAttribute('href',`${iconFile}`);
        document.getElementsByTagName('head')[0].appendChild(link)
    }
    iconSetter(icon,defaultIcon);
    iconSetter(aIcon,appleIcon);
}
