/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import Login from "../views/auth/Login";
import Register from "../views/auth/Register";
import ForgotPassword from '../views/auth/ForgotPassword';
import TransferBatchesData from '../views/departments/TransferBatchesData';
// core components
// import AuthNavbar from "../components/Navbars/AuthNavbar.js";
import AuthFooter from "../components/Footers/AuthFooter.js";

import "./Auth.css";
import ResetPassword from "../views/auth/ResetPassword";

// import routes from "../routes.js";

class Auth extends React.Component {
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    return (
      <>
        <div className="main-content">
          {/* <AuthNavbar /> */}
          <div className="header bg-image py-7 py-lg-8">
            <Container>
              <div className="header-body text-center mb-7">
                <Row className="justify-content-center mt-5">
                  <Col lg="5" md="6">
                  </Col>
                </Row>
              </div>
            </Container>
            {/* </div> */}
            <div className="separator separator-bottom separator-skew zindex-100">
            </div>
          </div>
          {/* Page content */}
          <Container className="mt--8">
            <Row className="justify-content-center">
              <Switch>
                <Route path="/auth/login" component={() => <Login />} />
                <Route path="/auth/register" component={() => <Register />} />
                <Route path="/auth/forgotpassword" component={() => <ForgotPassword /> } />
                <Route path="/auth/resetpassword" component={() => <ResetPassword /> } />
                <Route path="/auth/externalbatches" component={() => <TransferBatchesData /> } />
              </Switch>
            </Row>
          </Container>

          <AuthFooter />
        </div>
      </>
    );
  }
}

export default Auth;
