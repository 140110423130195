import React, { useState, useEffect } from 'react';
import Tables from '../../shared/Tables/Tables';
import { Row, Table } from 'reactstrap';
export default function SoTable(props) {
  const [selectedSO, setSelectedSO] = useState('');
  let table_headers = [
    { Header: 'CUSTOMER PO NO.', accessor: 'customer_po_number' },
    { Header: 'COTTON TYPE', accessor: 'cotton_type' },
    { Header: 'SO NO.', accessor: 'sales_order_number' },
    { Header: 'SO LINE ITEM', accessor: 'name' },
    { Header: 'CUSTOMER NAME', accessor: 'customer_name' },
    { Header: 'SO QUANTITY', accessor: 'base_quantity' },
    { Header: 'UNIT', accessor: 'base_unit' },
    { Header: 'DESCRIPTION', accessor: 'description' },
    { Header: 'SHADE', accessor: 'shade' },
    // { Header: 'SIZE', accessor: 'size' },
    // { Header: 'ACTION', accessor: 'TRACEABILITY_ACTION' }
  ];

  const [tableData, setTableData] = useState([]);
  

  useEffect(() => {
    setTableData(props.soList);

    return setSelectedSO('');
  }, [props.soList]);

  useEffect(()=>{
    
      if(!props.qrModal)
        setSelectedSO('')
  },[props.qrModal])

  const handleQrClicked = (e, data) => {
    e.stopPropagation();
    setSelectedSO(data);
    props.qrAction(data.has_qr_code, data);
  };

  const handleSOLineItemClicked = soLineItem => {
    props.selectedSo(soLineItem.name, soLineItem.has_qr_code);
    if (soLineItem.has_qr_code) setSelectedSO(soLineItem);
  };

  

  return (
    <Row className='card shadow'>
      <Tables
        data={tableData}
        headers={table_headers}
        handleClick={handleSOLineItemClicked}
        handleQrClicked={handleQrClicked}
        pageNumberTracker={props.pageNumberTracker}
        pageNumber={props.pageNumber}
      />
      {selectedSO ? (
        <div className='section-details d-none d-sm-none d-md-block d-lg-block bg-primary'>
          <button
            type='button'
            className='close mr-2 mb-2'
            aria-label='Close'
            onClick={() => {
              setSelectedSO('');
              props.selectedSo('', true);
            }}
          >
            <span className='text-white' aria-hidden='true'>
              &times;
            </span>
          </button>
          <Table striped responsive size='sm'>
            <tbody>
              <tr>
                <td>PO NO.</td>
                <td>{selectedSO.customer_po_number}</td>
              </tr>
              <tr>
                <td>Cotton Type</td>
                <td>{selectedSO.cotton_type}</td>
              </tr>
              <tr>
                <td>SO NO.</td>
                <td>{selectedSO.sales_order_number}</td>
              </tr>
              <tr>
                <td>Line Item</td>
                <td>{selectedSO.name}</td>
              </tr>
              <tr>
                <td>Customer</td>
                <td>{selectedSO.customer_name}</td>
              </tr>
              <tr>
                <td>Quantity</td>
                <td>
                  {selectedSO.base_quantity} {selectedSO.base_unit}
                </td>
              </tr>
              <tr>
                <td>Description</td>
                <td className='text-wrap'>{selectedSO.description}</td>
              </tr>
              <tr>
                <td>Shade</td>
                <td>{selectedSO.shade}</td>
              </tr>
              <tr>
                <td>Size</td>
                <td>{selectedSO.size}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      ) : null}
    </Row>
  );
}
