import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Progress,
  Row,
  Table,
  ListGroup,
  ListGroupItem,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import Moment from 'react-moment';
import AddDepartment from '../../shared/AddDepartment';
import Loader from '../../shared/Loader/Loader';
import {  titleCaseConverter } from '../../shared/utility';
import Validators from '../../shared/Validations';
import SearchAndFilter from '../../shared/Filters/SearchAndFilter';
import { getUsers, updateUser } from '../../store/actions/index';
import getAssetImage from '../../shared/ImageAssets';
function EditUser(props) {
  const dispatch = useDispatch();
  const headers = [
    { Header: 'USERNAME', accessor: 'name' },
    { Header: 'USER ID', accessor: 'email' },
    { Header: 'DATE OF CREATION', accessor: '' },
    { Header: 'STATUS', accessor: 'user_status' },
  ];

  const [isOpen, setIsOpen] = useState([]);
  const [nameProperties, setNameProperties] = useState({
    name: null,
    nameTouched: true,
    nameOkay: true,
  });
  const [userArray, setUserArray] = useState([]);
  const [addUserRenderer, setAddUserRenderer] = useState([]);
  const [activateUser, setActivateUser] = useState(null);
  const [hideDisableWarning, setHideDisableWarning] = useState(true);
  const [departmentList, setDepartmentList] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [hideAddMore, setHideAddMore] = useState(false);
  const [disableAddMore, setDisableAddMore] = useState(true);
  const [resetFields, setResetFields] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [isWorking, setIsWorking] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [filteredDisplayList,setFilteredDisplayList]=useState([])
  const [activeTouched,setActiveTouched]=useState(false)
  const [disableUpdateUser,setDisableUpdateUser]=useState(false)
  const [handleUpdateUser,setHandleUpdateUser]=useState(false)
  const validators = {
    name: {
      validators: (value) => Validators.fieldRequired(value),
      errorMessage: 'Name field cannot be blank or less than 3 characters ',
    },
  };

  const userFilterOptions = ['All','Active','Inactive','Blocked'];

  const functionTypeHandler = {
    nameProperties,
    setNameProperties,
  };


  const toggleEditModal = useCallback(() => {
    setOpenEditModal(!openEditModal);
    resetForm();
  },[openEditModal]);

  const users = useSelector((state) => state.users.users);
  // const updateUserLoading = useSelector(
  //   (state) => state.users.updateUserLoading
  // );
  const updateUserSuccess = useSelector(
    (state) => state.users.updateUserSuccess
  );
  // const updateUserMsg = useSelector((state) => state.users.updateUserMsg);
  const updateUserErrors = useSelector((state) => state.users.updateUserErrors);

  useEffect(()=>{
    setFilteredDisplayList(users)
  },[users])
  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const updateDisplayList = (filteredList) => {
    setFilteredDisplayList(filteredList)
  }


  const inputChangeHandler = (event, controlName) => {
    functionTypeHandler[`set${titleCaseConverter(controlName)}Properties`]({
      ...functionTypeHandler[`${controlName}Properties`],
      [`${controlName}`]: event.target.value,
      [`${controlName}Touched`]: true,
      [`${controlName}Okay`]: validators[controlName].validators(
        event.target.value
      ),
    });
  };

  useEffect(() => {
    if (isWorking && updating) {
      let userStatusData = {};
      let data={};
      if(activateUser!==null && activeTouched)
      {
        if(activateUser !== modalData.user_status.toLowerCase())
        {
          userStatusData = {
              email: modalData.email,
              status: activateUser
            }
        }
      }
        let updatedDepartments = [];
        if(handleUpdateUser)
        {
      if(userDetails[0].chosenDepartment.name.toLowerCase()==='all')
      {
        props.allDepartments.map(dept => updatedDepartments.push({
          department_id:dept.department_id,
          role: userDetails[0].chosenRole.role?userDetails[0].chosenRole.role:userDetails[0].chosenRole
        }))
      }
      else
      {
        userDetails.map((item) => {
          updatedDepartments.push({
            department_id: item.chosenDepartment.department_id
              ? item.chosenDepartment.department_id
              : item.chosenDepartment.id,
            role: item.chosenRole.role? item.chosenRole.role:item.chosenRole,
          });
          return null;
        });
      }
      data={
        email: modalData.email,
        name: nameProperties.name ? nameProperties.name : modalData.name,
        departments: updatedDepartments,
       }
    }
      setIsWorking(false);
      dispatch(updateUser(data,userStatusData));  
  }    
    if (updating && updateUserSuccess) {
      setUpdating(false);
      resetForm();
      toggleEditModal();
      setDisableUpdateUser(false)
      setHandleUpdateUser(false)
      setActiveTouched(false)
    }
    if (updating && updateUserErrors) {
      setUpdating(false);
      setDisableUpdateUser(false)
      setHandleUpdateUser(false)
      setActiveTouched(false)
    }
  },[isWorking, updating, updateUserSuccess, activateUser, modalData.user_status, updateUserErrors, userDetails, modalData.email, modalData.name,props.allDepartments, nameProperties.name, dispatch, toggleEditModal]);
  const toggle = (i) => {
    let tempIsOpen = new Array(i + 1).fill(false);
    tempIsOpen[i] = isOpen[i] ? false : true;
    setIsOpen(tempIsOpen);
  };

  useDispatch(() => {}, [users]);

  const handleEditClicked = (e, user) => {
    e.stopPropagation();
    setModalData(user);
    setActivateUser(user.user_status.toLowerCase())
    setOpenEditModal(true);
    let tempUserArray = new Array(user.department_members.length).fill(true);
    setUserArray(tempUserArray);
    setDisableUpdateUser(true)
    setAddUserRenderer(tempUserArray.map((_, i) => `_${i + 1}`));
    let tempUserDetails = user.department_members.map((member) => {
      return {
        chosenDepartment: {
          description: member.department.description,
          id: member.department.id,
          name: member.department.name,   
        },
        chosenRole:{
        role: member.role
        }
      };
    });
    setUserDetails(tempUserDetails);
    let tempDepartmentList = props.allDepartments.filter((dept) =>
      tempUserDetails
        .map((item) => item.chosenDepartment.name !== dept.name)
        .reduce((acc, cur) => {
          return acc && cur;
        }, true)
    );
    tempDepartmentList.push(
      ...props.allDepartments.filter(
        (item) =>
          item.name ===
          user.department_members[user.department_members.length - 1].department.name
      )
    );
    setDepartmentList(tempDepartmentList);
  };


  const resetForm = () => {
    setNameProperties({
      name: null,
      nameTouched: true,
      nameOkay: true,
    });
    setUserArray([]);
    setAddUserRenderer([]);
    setUserDetails([]);
    setModalData({})
    setHideAddMore(false)
    setDisableAddMore(true)
    setActivateUser(null)
    setHideDisableWarning(true)
  };

  const handleUpdate = () => {
    setIsWorking(true);
    setUpdating(true);
  };

  const removeDocAddWindow = (index, name) => {
    let tempUserAddRenderer = addUserRenderer
      .slice()
      .filter((item, i) => i !== index);
    let tempUserArray = userArray.slice().filter((item, i) => i !== index);
    let tempUserDetails = userDetails.slice().filter((item, i) => i !== index);
    
    setHideAddMore(false)
    if(index + 1 === userDetails.length && index!==0 )
    {
      setDepartmentList([
        ...departmentList,
        userDetails[index-1].chosenDepartment,
      ]);
    }
    else if(addUserRenderer.length <= props.allDepartments.length && addUserRenderer.length > 1)
    {
      if(userDetails[index] && userDetails[index].chosenDepartment)
      {
  setDepartmentList([
      ...departmentList,
      userDetails[index].chosenDepartment
  ]);
}
    }
    else {
      setDepartmentList(props.allDepartments);
    }
    setUserDetails(tempUserDetails);
    setAddUserRenderer(tempUserAddRenderer);
    setUserArray(tempUserArray);
    setHandleUpdateUser(true)
    setDisableUpdateUser(false)
    setDisableAddMore(true)
  };

  const selectMoreDepartment = (e) => {
    setUserArray([...userArray, false]);
    setAddUserRenderer([...addUserRenderer, `_${addUserRenderer.length + 1}`]);
    setDepartmentList(
      props.allDepartments.filter(dept =>
        userDetails
          .map(item => item.chosenDepartment.name !== dept.name)
          .reduce((acc, cur) => {
            return acc && cur;
          }, true)
      )
    );
    setDisableAddMore(false);
  };
  const trackChosenDepartment = (data) => {
    let tempUserDetails = userDetails.slice();
    tempUserDetails[data.index] = data.state;
    setUserDetails(tempUserDetails);
    setDisableAddMore(tempUserDetails[data.index].enableAddMore);
    let tempUserArray=userArray.slice()
    if(tempUserDetails[data.index])
        {
           if(
            data.state.chosenDepartment.name
             && (tempUserDetails[data.index].chooseRoleTouched
              && tempUserDetails[data.index].chooseRoleOkay)   ||
              (tempUserDetails[data.index].chooseDepartmentOkay
                && tempUserDetails[data.index].chooseDepartmentTouched ))
            {
              if(data.state.chosenRole!=='Choose Role')
              {
                   tempUserArray[data.index]=true
                   setDisableUpdateUser(false)
                   setHandleUpdateUser(true)
              }
            }
            else{
                tempUserArray[data.index]=false
            }
        }
        setUserArray(tempUserArray)
  };
  const handleEnableUpdate = () => {
    return (activeTouched && 
      userArray.reduce((total = false, amount) => total && amount) && handleUpdateUser?
    true:
    handleUpdateUser && 
    userArray.reduce((total = false, amount) => total && amount)?
    true:
    activeTouched?
    true:
    false)
  
  
    
  };
  const handleExpandCollapse = (value) => {
    setHideDisableWarning(value)
    setActivateUser(value===true?'active':'blocked')
    setActiveTouched(true)
    setDisableUpdateUser(false)
  }
  const resetAll = () => {setFilteredDisplayList(users); setResetFields(true)}
  return (
    <React.Fragment>
      {users && users.length ? (
      <Card className='shadow col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5 mb-5 px-0'>
        <CardHeader className='shadow text-left py-4'>
          <Row className="pb-2">
            <div className='col'>
              <span className='h2 text-left'>Existing Users</span>
            </div>
            </Row><Row>
            <div className='col col-8 offset-2 col-md-12 offset-md-0'>
              <SearchAndFilter
                dateSearchColumn={'inserted_at'}
                placeholderText='User ID'
                searchArray={users}
                searchColumn={'email'}
                lg='10'
                filterOptions={userFilterOptions}
                updateDisplayList={updateDisplayList}
                selectSearchColumn = {'user_status'}
                resetFields = {resetFields}
                  resetFieldsComplete={()=>setResetFields(false)}
                  resetAll={(resetAll)}
              />
            </div>
          </Row>
        </CardHeader>
        <CardBody className='px-0'>
              {/* <Tables headers={headers} data={users} /> */}
              <Table responsive hover>
                <thead>
                  <tr className='bg-lighter'>
                    <th  style={{ width: '15%' }}>
                      {''}
                    </th>
                    {headers.map((header, i) => (
                      <th key={i}>{header.Header}</th>
                    ))}
                    <th>{''}</th>
                  </tr>
                </thead>
                <tbody>
                {filteredDisplayList.length?
                  filteredDisplayList.map((user, i) => {
                    return (
                      <React.Fragment key={i}>
                        <tr
                          onClick={() => toggle(i)}
                          className={`showpointer ${
                            isOpen[i] ? 'bg-teal text-primary' : ''
                          }`}
                        >
                          <td >
                            {isOpen[i] ? (
                              <i className='fas fa-chevron-down text-primary font-weight-bold'></i>
                            ) : (
                              <i className='fas fa-chevron-right text-primary font-weight-bold'></i>
                            )}
                          </td>
                          <td className={isOpen[i] ? 'font-weight-bold' : ''}>
                            {user.name}
                          </td>
                          <td>{user.email}</td>
                          <td>
                            <Moment format='DD/MM/YYYY'>
                              {user.inserted_at}
                            </Moment>
                          </td>
                          <td className='text-capitalize'>
                            {user.user_status}
                          </td>
                          <td
                            onClick={(e) => handleEditClicked(e, user)}
                            className='px-5'
                          >
                            <img src={getAssetImage(isOpen[i]?'edit_green':'edit_black')} alt='edit' height="25%" />
                          </td>
                        </tr>
                        <tr className='p-0'>
                          <td colSpan='100%' className='p-0'>
                            <Collapse isOpen={isOpen[i]}>
                              <Card className='bg-lighter px-0'>
                                <CardBody>
                                  <div className='text-left pl-3'>
                                    <span className='text-primary font-weight-bold text-uppercase text-underline'>
                                      Existing User Roles
                                    </span>
                                  </div>

                                  <Row>
                                    <div className='col col-6'>
                                      <ListGroup>
                                        <ListGroupItem className='font-weight-bold text-uppercase text-primary border-0 pb-0 bg-lighter'>
                                        <div className="w-75">
                                             <Row>
                                               <Col size="6">
                                                 Department
                                               </Col>
                                               <Col size="6">
                                                 Role
                                               </Col>
                                             </Row>
                                          </div>
                                        </ListGroupItem>
                                        {user.department_members.map(
                                          (role, ix) => (
                                            <ListGroupItem
                                              key={ix}
                                              className='text-primary border-0 pb-0 bg-lighter text-left'
                                            >
                                              <div className="w-75">
                                                <Row>
                                                <Col size="6">
                                                
                                                {role.department.name}
                                              
                                              </Col>
                                              <Col size="6">
                                               {role.role} 
                                              </Col> 
                                                </Row>
                                               
                                              </div>
                                              
                                              
                                            </ListGroupItem>
                                          )
                                        )}
                                      </ListGroup>
                                    </div>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Collapse>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  }):<tr><td colSpan="100%" className="font-weight-bold">No Data Found</td></tr>}
                </tbody>
              </Table>
        </CardBody>
      </Card>
) : (
  <Loader />
)}
      <Modal
        isOpen={openEditModal}
        toggle={toggleEditModal}
        size='lg'
        backdrop={'static'}
        keyboard={updating ? false : true}
      >
        <ModalHeader toggle={updating ? () => false : toggleEditModal}>
          <span className='h3'>Edit Details</span>
        </ModalHeader>
        <ModalBody className="px-5">
          <Form>
            <Label className='h4 pl-2 pb-3 text-uppercase'>User Details</Label>
            <FormGroup  size="sm">
            <InputGroup className="input-group-alternative border-style w-75">
            <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          {/* <img
                            alt="..."
                            src={require("../../assets/img/icons/mail.svg")}
                            width="80%"
                          /> */}
                          <i className="fas fa-envelope text-primary"></i>
                        </InputGroupText>
                      </InputGroupAddon>
              <Input
                placeholder="Enter Email"
                autoComplete='off'
                value={modalData.email || ''}
                disabled
                className="font-weight-bold text-secondary"
              />
              </InputGroup>
            </FormGroup>
            <FormGroup>
            <InputGroup className="input-group-alternative border-style w-75">
            <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          {/* <img
                            alt="..."
                            src={require("../../assets/img/icons/name.png")}
                            width="100%"
                          /> */}
                          <i className="fas fa-user text-primary"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                  <Input
                  placeholder="Enter Name"
                    autoComplete='off'
                    className="text-secondary font-weight-bold"
                    disabled
                    value={
                      (nameProperties.name === null
                        ? modalData.name
                        : nameProperties.name) || ''
                    }
                    onChange={(e) => inputChangeHandler(e, 'name')}
                  />
                  </InputGroup>
                  {nameProperties.nameTouched && !nameProperties.nameOkay ? (
                    <span className='text-danger'>
                      <small>{validators['name'].errorMessage}</small>
                    </span>
                  ) : null}
                  
            </FormGroup>
            <div className="pt-4 pl-2 ">
            <Label className='h4 text-uppercase'>User Departments and Roles</Label>
            <Row className="pt-3">
              <div className="col col-6">
                <span className='text-secondary h4'>Department</span>
              </div>
              <div className="col col-1"></div>
              <div className="col col-3">
                <span className='text-secondary h4'>User Role</span>
              </div>
            </Row>
            {addUserRenderer && addUserRenderer.length ? (
              addUserRenderer.map((member, index) => (
                <Row key={index}>
                  <Col size='10'>
                    <AddDepartment
                      index={index}
                      key={index}
                      removeDocAddWindow={removeDocAddWindow}
                      selectMoreDepartment={selectMoreDepartment}
                      allDepartments={props.allDepartments}
                      userArray={userArray}
                      addUserRenderer={addUserRenderer}
                      departmentList={departmentList}
                      trackChosenDepartment={trackChosenDepartment}
                      handleEnableAdd={handleEnableUpdate}
                      userDetails={userDetails}
                      
                    />
                  </Col>
                </Row>
              ))
            ) : (
              <div className="pt-3 justify-content-center">
                <span className="h5">No Departments and Roles Assigned</span>
              </div>
            )}

            <Row>
              <FormGroup className='mt-2'>
                <Button
                  className='btn-border-style-outline btn-outline-primary m-0 ml-3 mt-4'
                  color='primary'
                  type='button'
                  disabled={addUserRenderer.length ? !disableAddMore : false}
                  hidden={
                  hideAddMore || addUserRenderer.length===props.allDepartments.length ? true : null
                  }
                  onClick={(e) => selectMoreDepartment(e)}
                >
                  {addUserRenderer.length ? 'Add More' : 'Assign Department'}
                </Button>
              </FormGroup>
            </Row>
            </div>
            <div className="w-100 mt-4 py-2 border border-dark border rounded">
              <Label className="font-weight-bold d-block pl-3">Change User Status
            </Label>
            <Row className=' ml-auto pl-3'>
            
                <label style={{ width: '220px' }}>Current Status : <strong>{activateUser?activateUser.toUpperCase():''}</strong></label>
          <label className='custom-toggle'>
            <input
              type='checkbox'
              checked={activateUser==='active'?true:false}
              onChange={e => handleExpandCollapse(e.target.checked)}
            />
            <span className='custom-toggle-slider rounded-circle bg-white border border-primary' />
          </label>
          {
            hideDisableWarning?null:
            <Alert color='warning'>
                  <strong>Warning!</strong> {modalData.name} will no longer have access to this application
                </Alert>
          }
          
        </Row>
            </div>
            
            {/* <Row>
            <FormGroup className=" pl-2 ml-3 ">
            <Label className="font-weight-bold ">Deactivate User
            </Label>
            <label className="custom-toggle">
              <input type="checkbox" />
              <span className="custom-toggle-slider rounded-circle" />
            </label>
            </FormGroup>
            </Row> */}
          </Form>
        </ModalBody>
        <ModalFooter>
          {updating ? (
            <div className='w-100'>
              <Progress animated bar value='100'>
                <strong>Please Wait...</strong>
              </Progress>
            </div>
          ) : (
            <>
              <Button
                className='btn-border-style-outline'
                color='primary'
                type='button'
                disabled={addUserRenderer.length===0?true:false || !handleEnableUpdate() || disableUpdateUser}
                onClick={handleUpdate}
              >
                Update User
              </Button>
              <Button
                className='btn-border-style-outline'
                color='light'
                data-dismiss='modal'
                type='button'
                onClick={toggleEditModal}
              >
                Cancel
              </Button>
            </>
          )}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
export default React.memo(EditUser);
