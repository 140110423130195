/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Alert
} from "reactstrap";
import Validators from "../../shared/Validations";
import {signIn, resetAlerts, checkAuthState} from "../../store/actions";
import Logo from "../../assets/img/brand/login_logo.png";
import { simpleErrorMsg, titleCaseConverter } from "../../shared/utility";

function Login() {
  const dispatch = useDispatch();
  const [emailProperties, setEmailProperties] = useState({
    email: "",
    emailTouched: false,
    emailOkay: false,
    emailClicked: false
  });
  const [passwordProperties, setPasswordProperties] = useState({
    password: "",
    passwordClicked: false,
    passwordTouched: false,
    passwordOkay: false
  });
  const functionTypeHandler = {
    emailProperties,
    setEmailProperties,
    passwordProperties,
    setPasswordProperties
  };

  const [enableSubmit, setEnableSubmit] = useState(false);
  const validators = {
    email: {
      validators: value => Validators.email(value),
      errorMessage: "Invalid Email"
    },
    password: {
      validators: value => Validators.fieldRequired(value, 0),
      errorMessage: "Password cannot be blank"
    }
  };

  const loading = useSelector(state => state.auth.loading);
  const error = useSelector(state => state.auth.errors);
  const errorMessage = useSelector(state => state.auth.msg);
  const success = useSelector(state => state.auth.success);
  const isAuthenticated = useSelector(state => state.auth.email !== null);
  const authRedirectPath = useSelector(state => state.auth.authRedirectPath);

  const submitHandler = event => {
    event.preventDefault();
    inputBlurHandler(emailProperties.email, "email");
    inputBlurHandler(passwordProperties.password, "password");
    if (handleEnableSubmit())
      dispatch(signIn(emailProperties.email, passwordProperties.password));
  };

  const inputFocusHandler = controlName => {
    if(success || error)
    {
      dispatch(resetAlerts(0))
    }
    functionTypeHandler[`set${titleCaseConverter(controlName)}Properties`]({
      ...functionTypeHandler[`${controlName}Properties`],
      [`${controlName}Clicked`]: true
    });
  };

  const inputChangeHandler = (value, controlName) => {
    functionTypeHandler[`set${titleCaseConverter(controlName)}Properties`]({
      ...functionTypeHandler[`${controlName}Properties`],
      [`${controlName}`]: value,
      [`${controlName}Touched`]: true,
      [`${controlName}Okay`]: validators[controlName].validators(value)
    });
    setEnableSubmit(handleEnableSubmit());
  };

  const inputBlurHandler = (value, controlName) => {
    functionTypeHandler[`set${titleCaseConverter(controlName)}Properties`]({
      ...functionTypeHandler[`${controlName}Properties`],
      [`${controlName}Clicked`]: false,
      [`${controlName}`]: value,
      [`${controlName}Touched`]: true,
      [`${controlName}Okay`]: validators[controlName].validators(value)
    });
  };

  const handleEnableSubmit = () => {
    return (
      emailProperties.emailTouched &&
      emailProperties.emailOkay &&
      passwordProperties.passwordTouched &&
      passwordProperties.passwordOkay
    );
  };

  useEffect(() => {
    dispatch(checkAuthState());
  }, [dispatch]);

  let authRedirect = null;

  if (isAuthenticated && authRedirectPath !== "/auth/login") {
    authRedirect = <Redirect to={authRedirectPath} />;
  }
  return (
    <>
      <Container className='mt--8 pb-5'>
        <Row className='justify-content-center'>
          <Col lg="5" md="7">
            {authRedirect}
            <Card className="shadow">
              <CardHeader className="px-lg-7 py-lg-5">
                <div className=" text-primary text-uppercase h4">
                  Welcome
                  {/* <span className="border-bottom border-primary pb-1">We</span><span>lcome!</span> */}
                </div>
                <div className="text-secondary h4 text-uppercase">
                  traceability on blockchain
                </div>
                <div className="text-center text-muted my-4">
                  <img src={Logo} alt="logo" width="100%" />
                </div>
                <Form
                  role="form"
                  onKeyPress={e =>
                    e.key === "Enter" && enableSubmit ? submitHandler(e) : null
                  }
                >
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative border-style">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <img
                            alt="..."
                            src={require("../../assets/img/icons/mail.svg")}
                            width="80%"
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        autoComplete="off"
                        placeholder="Email"
                        type="email"
                        onFocus={() => inputFocusHandler("email")}
                        onChange={e => {
                          inputChangeHandler(e.target.value, "email");
                        }}
                        onBlur={event => {
                          inputBlurHandler(event.target.value, "email");
                        }}
                      />
                    </InputGroup>
                    {emailProperties.emailTouched &&
                    !emailProperties.emailOkay &&
                    !emailProperties.emailClicked
                      ? simpleErrorMsg(validators["email"].errorMessage)
                      : null}
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative border-style">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <img
                            alt="..."
                            src={require("../../assets/img/icons/lock.svg")}
                            width="80%"
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        autoComplete="off"
                        placeholder="Password"
                        type="password"
                        onFocus={() => inputFocusHandler("password")}
                        onChange={e => {
                          inputChangeHandler(e.target.value, "password");
                        }}
                        onBlur={event => {
                          inputBlurHandler(event.target.value, "password");
                        }}
                      />
                    </InputGroup>
                    {passwordProperties.passwordTouched &&
                    !passwordProperties.passwordOkay &&
                    !passwordProperties.passwordClicked
                      ? simpleErrorMsg(validators["password"].errorMessage)
                      : null}
                  </FormGroup>
                  {/* <div className="custom-control custom-control-alternative custom-checkbox">
<input
className="custom-control-input"
id=" customCheckLogin"
type="checkbox"
/>
<label
className="custom-control-label"
htmlFor=" customCheckLogin"
>
<span className="text-muted">Remember me</span>
</label>
</div> */}
                  <div className="text-center">
                    <Button
                      className="my-4 btn-block btn-border-style text-uppercase"
                      type="button"
                      color="primary"
                      onClick={submitHandler}
                      disabled={loading}
                    >
                      <span className="h3 text-white">Log in</span>
                    </Button>
                  </div>
                </Form>
                {error ? (
                  <Alert color="warning">
                    <strong>Warning!</strong> {errorMessage}
                  </Alert>
                ) : null}
                <div className='text-right'>
                    <Link to='forgotpassword'>
                      <small>Forgot password?</small>
                    </Link>
                  </div>
                
              </CardHeader>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                {/* <a
                  className="text-light"
                  href="#test"
                  onClick={e => e.preventDefault()}
                  >
                  <small>Forgot password?</small>
                  </a> */}
              </Col>
              {/* <Col className="text-right" xs="6">
                  <a
                  href="/auth/register"
                  // onClick={e => e.preventDefault()}
                  >
                  <small>Create new account</small>
                  </a>
                  </Col> */}
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center font-weight-bold">
          Powered by Infinichains &copy;
        </Row>
      </Container>
    </>
  );
}

export default Login;
