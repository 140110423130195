/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// reactstrap components
import {
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Nav,
  Navbar,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from 'reactstrap';
import { getFromLocalStorage, backendRoutes } from '../../shared/utility';
import LogoutIcon from '../../assets/img/icons/logout.png';
class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      warningModalOpen:false,
      resetMessage:'',
      resetActive:false
    };
  }

  toggleWarningModal = () => {
    this.setState({warningModalOpen: !this.state.warningModalOpen})
  }
  resetDemoData  = () => {
    this.setState({resetActive:true})
    fetch(`${process.env.REACT_APP_BASE_URL+backendRoutes.resetDemoData}`)
    .then(response => response.json())
    .then(response => {
     this.setState({resetMessage: response.result.payload.msg, resetActive:false});
     localStorage.removeItem("ICBL900441");
     setTimeout(()=>{
      this.setState({resetMessage:'', warningModalOpen: false})
     },1500);
     
    })
    .catch(err => {
      this.setState({resetMessage: err.message, resetActive:false});
    })
  }
  render() {
    let loggedInUser = getFromLocalStorage('name');
    let loggedInEmail = getFromLocalStorage('email');
    let showReset = loggedInEmail === process.env.REACT_APP_FRAGMENTED_USER?true:false;
    return (
      <>
        <Navbar className='navbar-top navbar-dark' expand='md' id='navbar-main'>
          <Container fluid>
            <Nav className='align-items-center d-none d-md-flex ml-auto' navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className='pr-0' nav>
                  <Media className='align-items-center'>
                    <span>
                      <img
                        alt='...'
                        src={require('../../assets/img/theme/user.png')}
                        width='80%'
                      />
                    </span>
                    <Media className='d-lg-block'>
                      <span className='mb-0 text-md font-weight-bold d-none d-lg-block text-primary '>
                        {loggedInUser}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className='dropdown-menu-arrow' right>
                  <DropdownItem className='noti-title' header tag='div'>
                    <h6 className='text-overflow m-0'>Welcome!</h6>
                  </DropdownItem>
                    {
                      showReset? 
                      <>
                      <DropdownItem divider />
                      <DropdownItem onClick={this.toggleWarningModal}>
                        <i className="fa fa-undo mr-0 text-warning"></i>
                        <span className='ml-2 text-warning showpointer'>Reset</span>
                      </DropdownItem>
                      </>:null
                    }
                  <DropdownItem divider />
                  <DropdownItem onClick={this.props.logout}>
                    <img src={LogoutIcon} alt='logout' height='20px' />
                    <span className='ml-2 showpointer'>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
        <Modal
          isOpen={this.state.warningModalOpen}
          toggle={this.toggleWarningModal}
        >
          <ModalHeader toggle={this.toggleWarningModal}>
            Confirm Reset
          </ModalHeader>
          <ModalBody>
            <div className='mt-2'>
              <span className='text-warning font-weight-bold'>
                This process is irreversible.
              </span>
            </div>
            <div className='mt-2'>
              Are you sure you want to reset the setup?
            </div>
          </ModalBody>
          <div align="center"><span className="text-dark">{this.state.resetMessage}</span></div>
          <ModalFooter className='bg-secondary'>
            <Button color='warning' onClick={this.resetDemoData} disabled={this.state.resetActive}>
              Reset
            </Button>{' '}
            <Button color='white' onClick={this.toggleWarningModal} disabled={this.state.resetActive}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default AdminNavbar;
