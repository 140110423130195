import { api } from '../../axios-instance';
import { backendRoutes, headers } from '../../shared/utility';
import * as actionTypes from './action-types';

export const externalBatchesStart = _ => ({
  type: actionTypes.EXTERNAL_BATCHES_START
});

export const externalBatchesSuccess = batches => ({
  type: actionTypes.EXTERNAL_BATCHES_SUCCESS,
  batches
});

export const externalBatchesFail = (error, msg) => ({
  type: actionTypes.EXTERNAL_BATCHES_FAIL,
  error,
  msg
});

export const resetTransferBatchesMessages = () => ({
  type: actionTypes.RESET_TRANSFER_BATCHES_MESSAGES
});

export const resetTransferBatchesAlerts = (timer = 3000) => {
  return dispatch => {
    setTimeout(() => {
      dispatch(resetTransferBatchesMessages());
    }, timer);
  };
}

export const externalBatches = (payload) => {
  return dispatch => {
    dispatch(externalBatchesStart());
    api
      .post(backendRoutes.externalBatches, payload, headers())
      .then(response => {
        localStorage.setItem('ICBL900441','yes');
        dispatch(externalBatchesSuccess(response.data.result.payload));
      })
      .catch(error => {
        dispatch(externalBatchesFail(error, error.message));
        dispatch(resetTransferBatchesAlerts());
      });
  };
};

export const checkTransferTokenStart = _ => ({
  type: actionTypes.CHECK_TRANSFER_TOKEN_START
});

export const checkTransferTokenSuccess = data => ({
  type: actionTypes.CHECK_TRANSFER_TOKEN_SUCCESS,
  data: data
});

export const checkTransferTokenFail = (error,msg) => ({
  type: actionTypes.CHECK_TRANSFER_TOKEN_FAIL,
  msg,
  error
});

export const checkTransferToken = (token) => {
  return (dispatch => {
    dispatch(checkTransferTokenStart())
    api.get(backendRoutes.getTransferData(token))
      .then(response => {
          dispatch(checkTransferTokenSuccess(response.data.result.payload))
      }).catch(error => {
        dispatch(checkTransferTokenFail(error, error.message))
      })
  })
}

export const requestBatchesStart = _ => ({
  type: actionTypes.REQUEST_BATCHES_START
});

export const requestBatchesSuccess = batches => ({
  type: actionTypes.REQUEST_BATCHES_SUCCESS,
  batches
});

export const requestBatchesFail = (error, msg) => ({
  type: actionTypes.REQUEST_BATCHES_FAIL,
  error,
  msg
});

export const resetRequestBatchesMessages = () => ({
  type: actionTypes.RESET_REQUEST_BATCHES_MESSAGES
});

export const resetRequestBatchesAlerts = (timer = 3000) => {
  return dispatch => {
    setTimeout(() => {
      dispatch(resetRequestBatchesMessages());
    }, timer);
  };
}

export const requestBatches = (payload) => {
  return dispatch => {
    dispatch(requestBatchesStart());
    api
      .post(backendRoutes.requestBatches, payload, headers())
      .then(response => {
        dispatch(requestBatchesSuccess(response.data.result));
        dispatch(resetRequestBatchesAlerts());
      })
      .catch(error => {
        dispatch(requestBatchesFail(error, error.message));
      });
  };
};

