import * as actionTypes from '../actions/action-types';
import { updateObject } from '../../shared/utility';

const initialState = {
  success: null,
  loading: false,
  errors: null,
  msg: null,
  user: {},
  getUsersSuccess: null,
  getUsersLoading: false,
  getUsersErrors: null,
  getUsersMsg: null,
  users: [],
  updateUserSuccess: null,
  updateUserLoading: false,
  updateUserErrors: null,
  updateUserMsg: null,
};

const createUserStart = (state, action) => {
  return updateObject(state, {
    errors: null,
    loading: true,
    success: null,
    msg: null,
    user: {},
  });
};
const createUserSuccess = (state, action) => {
  return updateObject(state, {
    errors: null,
    loading: false,
    success: true,
    msg: action.msg,
    user: action.user,
  });
};

const createUserFail = (state, action) => {
  return updateObject(state, {
    errors: true,
    msg: action.msg,
    loading: false,
    success: false,
    user: {},
  });
};
const resetMessages = (state, action) => {
  return updateObject(state, {
    msg: null,
    errors: null,
    success: null,
    loading: false
  });
};
const getUsersStart = (state, action) => {
  return updateObject(state, {
    getUsersSuccess: null,
    getUsersLoading: true,
    getUsersErrors: null,
    getUsersMsg: null,
    users: [],
  });
};
const getUsersSuccess = (state, action) => {
  return updateObject(state, {
    getUsersSuccess: true,
    getUsersLoading: false,
    getUsersErrors: null,
    getUsersMsg: null,
    users: action.users,
  });
};

const getUsersFail = (state, action) => {
  return updateObject(state, {
    getUsersSuccess: null,
    getUsersLoading: false,
    getUsersErrors: true,
    getUsersMsg: action.msg,
    users: [],
  });
};

const updateUserStart = (state, action) => {
  return updateObject(state, {
   updateUserSuccess: null,
   updateUserLoading: true,
   updateUserErrors: null,
   updateUserMsg: null
  });
};
const updateUserSuccess = (state, action) => {
  return updateObject(state, {
   updateUserSuccess: true,
   updateUserLoading: false,
   updateUserErrors: null,
   updateUserMsg: null
  });
};

const updateUserFail = (state, action) => {
  return updateObject(state, {
   updateUserSuccess: null,
   updateUserLoading: false,
   updateUserErrors: true,
   updateUserMsg: action.msg
  });
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_USER_START:
      return createUserStart(state, action);
    case actionTypes.CREATE_USER_SUCCESS:
      return createUserSuccess(state, action);
    case actionTypes.CREATE_USER_FAIL:
      return createUserFail(state, action);
    case actionTypes.RESET_MESSAGES:
        return resetMessages(state, action);  
    case actionTypes.GET_USERS_START:
      return getUsersStart(state, action);
    case actionTypes.GET_USERS_SUCCESS:
      return getUsersSuccess(state, action);
    case actionTypes.GET_USERS_FAIL:
      return getUsersFail(state, action);
      case actionTypes.UPDATE_USER_START:
        return updateUserStart(state, action);
      case actionTypes.UPDATE_USER_SUCCESS:
        return updateUserSuccess(state, action);
      case actionTypes.UPDATE_USER_FAIL:
        return updateUserFail(state, action);

    default:
      return state;
  }
};
