import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  CardBody,
  Label,
  CustomInput,
  Progress
} from 'reactstrap';
import Logo from '../../assets/img/brand/login_logo.png';
import { titleCaseConverter,fileTypeValidator } from '../../shared/utility';
import Validators from '../../shared/Validations';
import Loader from '../../shared/Loader/Loader';

import { checkTransferToken ,externalBatches} from '../../store/actions/index';

export default function TransferBatchesData(props) {

  const [verifyTokenLoading, setVerifyTokenLoading] = useState(true);
  const [tokenOkay, setTokenOkay]= useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [isTransfering, setIsTransfering] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const [selectedFileProperties, setSelectedFileProperties] = useState({
    selectedFile: {},
    selectedFileTouched: false,
    selectedFileOkay: false,
  });

  const validators = {
     selectedFile: {
        validators: (obj) => Validators.isObjectType(obj, 'fileOkay'),
        errorMessage: selectedFileProperties.selectedFile.name
          ? 'Invalid File Format. File should be of type XLSX'
          : 'File not selected',
      },
  };

    
  const  loading = useSelector(state => state.externalBatches.loading)
  const  success = useSelector(state => state.externalBatches.success)
  const  msg = useSelector(state => state.externalBatches.msg)
  const  error = useSelector(state => state.externalBatches.error)
  const  transferBatchesDetails = useSelector(state => state.externalBatches.transferBatchesDetails)
  const  checkSuccess = useSelector(state => state.externalBatches.checkSuccess)

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(()=>{
    if(verifyTokenLoading && !isWorking)
    {
      dispatch(checkTransferToken(new URLSearchParams(location.search).get('token')));
      setIsWorking(true)
    }
    if(verifyTokenLoading && isWorking && checkSuccess)
    {
      setTokenOkay(true)
      setIsWorking(false)
      setVerifyTokenLoading(false);
    }
  },[verifyTokenLoading, isWorking, dispatch, location.search, checkSuccess])

 const resetForm = () =>{
   setSelectedFileProperties({
     selectedFile: {},
     selectedFileTouched: false,
     selectedFileOkay : false
   })
 }
  const functionTypeHandler = {
    selectedFileProperties,
    setSelectedFileProperties,
  };

  const handleEnableTransfer = () => {
    return (selectedFileProperties.selectedFileTouched &&
    selectedFileProperties.selectedFileOkay );
  };

  const transferHandler = event => {
    inputBlurHandler('selectedFile');
    if (handleEnableTransfer()) {
      setIsTransfering(true)
      setIsChecked(true)
    }
  };

  useEffect(()=>{
    if(isTransfering && isChecked){
      let payload = {
        token :new URLSearchParams(location.search).get('token'),
        file : {
          file_name: selectedFileProperties.selectedFile.name,
          file_base64_encoded: selectedFileProperties.selectedFile.imagePreviewUrl.split(
            'base64,'
          )[1],
      product: "Terry"
    }
  }
      dispatch(externalBatches(payload));
      setIsChecked(false)  
    }
    if(!loading && success && isTransfering)
    {
      resetForm();
      setIsTransfering(false);
    }
    else if(!loading && error && isTransfering)
    {
      setIsTransfering(false);
    }
    
  },[isTransfering, isChecked, loading, success, error, dispatch ])

  const inputChangeHandler = (value, controlName) => {
    functionTypeHandler[`set${titleCaseConverter(controlName)}Properties`]({
      ...functionTypeHandler[`${controlName}Properties`],
      [`${controlName}`]: value,
      [`${controlName}Touched`]: true,
      [`${controlName}Okay`]: true
    });
  };

  const inputBlurHandler = (controlName) => {
    functionTypeHandler[`set${titleCaseConverter(controlName)}Properties`]({
      ...functionTypeHandler[`${controlName}Properties`],
      [`${controlName}`]: functionTypeHandler[`${controlName}Properties`][
        `${controlName}`
      ],
      [`${controlName}Touched`]: true,
      [`${controlName}Okay`]: validators[controlName].validators(
        functionTypeHandler[`${controlName}Properties`][`${controlName}`]
      ),
    });
  };

  const getFile = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        let fileType = reader.result.split(';')[0];
        let targetObject = {
          file: file,
          imagePreviewUrl: reader.result,
          name: file.name,
        };
        if (fileTypeValidator(fileType) === 'ms-excel') {
          inputChangeHandler(
            { ...targetObject, fileOkay: true },
            'selectedFile'
          );
        } else {
          inputChangeHandler(targetObject, 'selectedFile');
        }
      };
    }
  };

  return (
    <div>
      <Container className="mt--9 pb-8 ">
        <Row className="justify-content-center">
          <Col lg="8" md="8">
            <Card className="shadow">
              <CardHeader>
                <Row>
                  <Col className="col-md-6 mt-2">
                    <div className=" text-secondary text-uppercase h4 ml-2">
                      Transfer Batches Data
                    </div>
                  </Col>
                  <Col className="col-md-6 mt-2">
                    <div>
                      <img
                        src={Logo}
                        alt="logo"
                        width="30%"
                        className="float-right mr-3"
                      />
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {success? (
                  <Alert color='success'>
                    <strong>Data Transferred Successfully!</strong>
                  </Alert>
                ) : tokenOkay ? (
                  <div className="mt-1">
                    <FormGroup>
                      <Row>
                        <Col className="col-md-6 mt-2">
                          <Label>Requesting Organisation</Label>
                          <Input
                            className="border theme-border-color rounded p-2"
                            disabled={true}
                            value={transferBatchesDetails ? transferBatchesDetails.org_id : null}
                          />
                        </Col>
                        <Col className="col-md-6 mt-2">
                          <Label>Requesting Department</Label>
                          <Input
                            className="border theme-border-color rounded p-2"
                            disabled={true}
                            value={transferBatchesDetails ? transferBatchesDetails.batch_details.department : null}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col className="col-md-6 mt-2">
                          <Label>Requesting Batch Type</Label>
                          <Input
                            className="border theme-border-color rounded p-2"
                            disabled={true}
                            value={transferBatchesDetails ? transferBatchesDetails.batch_details.stage : null}
                          />
                        </Col>
                        <Col className="col-md-6 mt-2">
                          <Label>Batch ID</Label>
                          <Input
                            className="border theme-border-color rounded p-2"
                            disabled={true}
                            value={transferBatchesDetails ? transferBatchesDetails.batch_details.batch_number : null}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Label className="ml-3">Invoice Number</Label>
                        <Input
                          className="border theme-border-color rounded p-2 ml-3 mr-3"
                          disabled={true}
                          value={transferBatchesDetails ? transferBatchesDetails.batch_details.invoice_no : null}
                        />
                      </Row>
                    </FormGroup>
                    <Label>Select File</Label>
                    <FormGroup>
                      <CustomInput
                        className="w-100 text-truncate"
                        type="file"
                        id="exampleCustomFileBrowser"
                        label={
                          selectedFileProperties.selectedFile
                            ? selectedFileProperties.selectedFile.name
                            : 'Choose File'
                        }
                        name="customFile"
                        onChange={getFile}
                      />
                      <div className="ml-2">
                        {selectedFileProperties.selectedFileTouched &&
                        !selectedFileProperties.selectedFileOkay ? (
                          <span className="text-danger">
                            <small>
                              {validators.selectedFile.errorMessage}
                            </small>
                          </span>
                        ) : null}
                      </div>
                    </FormGroup>
                    <Row>
                      {loading ? (
                        <div className="w-100 p-3">
                          <Progress animated bar value="100">
                            <strong>Please Wait...</strong>
                          </Progress>
                        </div>
                      ) : null}
                    </Row>
                    <Row>
                    <Col className="col-md-3 mt-2">
                    <Button
                      className="btn-border-style-outline float-left"
                      color="primary"
                      onClick={() => transferHandler()}
                      hidden={loading}
                    >
                      Transfer
                    </Button>{' '}
                    </Col>
                    <Col className="col-md-6 mt-2">
                    {
                !isChecked && !loading && error && !isTransfering && tokenOkay ?
                <Alert color='warning'>
                <strong>Warning!</strong> {`Something Went Wrong!`}
              </Alert>
                :null
              }
                    </Col>
                    </Row>
                  </div>
                ) : 
                !tokenOkay && !isChecked && !verifyTokenLoading? <Alert color='warning'>
                <strong>Warning!</strong> {`Invalid Transfer Link`}
              </Alert>:<><div className="d-flex justify-content-center"><Loader /></div></> }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
