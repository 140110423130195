/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
// nodejs library to set properties for components
import { PropTypes } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink as NavLinkRRD } from 'react-router-dom';
// reactstrap components
import {
  Col,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  Row,
  UncontrolledDropdown,
  Dropdown
} from 'reactstrap';
import {
  default as Brand,
  default as SidebarLogo
} from '../../assets/img/brand/login_logo.png';
import LogoutIcon from '../../assets/img/icons/logout.png';
import { getFromLocalStorage, routeGenerator } from '../../shared/utility';
import './Sidebar.css';

var ps;
class Sidebar extends React.Component {
  state = {
    collapseOpen: false
  };
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
    this.state = {
      homeRoute: '/admin/departments/index'
    };
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false
    });
  };
  dropDown = (routes, name) => {
    return routes.map((route, index) => (
      <NavItem key={index}>
        <NavLink
          to={route.layout + route.path}
          tag={NavLinkRRD}
          activeClassName='text-darker'
          className='sidebar-nav mr-auto border-0 bg-dark text-white pl-4'
        >
          {route.name}
        </NavLink>
      </NavItem>
    ));
  };

  // creates the links that appear in the left menu / Sidebar
  createLinks = routes => {
    return routes.map((prop, key) => {
      return prop.hasOwnProperty('routes') ? (
        <React.Fragment key={key}>
          <NavItem key={key}>
            <span
              tag={NavLinkRRD}
              // activeClassName='active'
              className={`sidebar-nav mr-auto nav-link showpointer text-white ${this.state[prop.name.toLowerCase()]?'border-0':'border-bottom border-light'}`}
              onClick={() => {
                this.state[prop.name.toLowerCase()]
                  ? this.setState({
                      [prop.name.toLowerCase()]: !this.state[
                        prop.name.toLowerCase()
                      ]
                    })
                  : this.setState({ [prop.name.toLowerCase()]: true });
              }}
            >
              {prop.name}
              {this.state[prop.name.toLowerCase()] ? (
                <i className='fas fa-chevron-down ml-auto' />
              ) : (
                <i className='fas fa-chevron-right ml-auto' />
              )}
            </span>
          </NavItem>
          <Collapse isOpen={this.state[prop.name.toLowerCase()]}>
            {this.dropDown(prop.routes)}
          </Collapse>
        </React.Fragment>
      ) : (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={this.closeCollapse}
            activeClassName='bg-dark text-darker'
            className='sidebar-nav mr-auto border-bottom border-light text-white'
          >
            {prop.name}
            <i className='fas fa-chevron-right ml-auto' />
          </NavLink>
        </NavItem>
      );
    });
  };
  render() {
    const { bgColor, routes, logo } = this.props;
    let navbarBrandProps;
    let availableRoutes = routeGenerator();
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: '_blank'
      };
    }
    return (
      <Navbar
        className='navbar-vertical fixed-left navbar-light bg-primary shadow'
        expand='md'
        id='sidenav-main'
      >
        <Container fluid className='nav-bar-custom'>
          <div className='bg-white row brand-image collapse navbar-collapse'>
            <NavbarBrand className='pt-2' {...navbarBrandProps}>
              <img alt='Logo' className='navbar-brand-img ' src={SidebarLogo} />
            </NavbarBrand>
          </div>
          {/* Toggler */}
          <button
            className='navbar-toggler'
            type='button'
            onClick={this.toggleCollapse}
          >
            <span className='navbar-toggler-icon navbar-dark' />
          </button>
          {/* Brand */}
          {/* {logo ? ( */}

          {/* : null}) */}
          {/* User */}
          <Nav className='align-items-center d-md-none'>
            <UncontrolledDropdown nav>
              <DropdownToggle nav>
                <Media className='align-items-center'>
                  <span className=' rounded-circle'>
                    <img
                      alt='...'
                      src={require('../../assets/img/theme/user.png')}
                      width=' 80%'
                    />
                  </span>
                </Media>
              </DropdownToggle>
              <DropdownMenu className='dropdown-menu-arrow mt--3' right>
                <DropdownItem className='noti-title' header tag='div'>
                  <h6 className='text-overflow m-0'>Welcome!</h6>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={this.props.logout}>
                  <img src={LogoutIcon} alt='logout' height='20px' />
                  <span className='ml-2'>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          {/* Collapse */}
          <Collapse navbar isOpen={this.state.collapseOpen}>
            {/* Collapse header */}
            <div className='navbar-collapse-header d-md-none'>
              <Row>
                <Col className='collapse-brand' xs='6'>
                  <Link to={this.state.homeRoute} onClick={this.toggleCollapse}>
                    <img alt={'Logo'} src={Brand} />
                  </Link>
                </Col>
                <Col className='collapse-close' xs='6'>
                  <button
                    className='navbar-toggler'
                    type='button'
                    onClick={this.toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav navbar>{this.createLinks(availableRoutes)}</Nav>
            {/* <Nav navbar className="pt-5 showpointer"><NavItem><Link to="/admin/departments/index" className="text-white sidebar-nav border-0"
            ><i className="fas fa-chevron-left" />&nbsp; Go Back</Link></NavItem></Nav> */}
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: []
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired
  })
};

const mapStateToProps = state => ({
  availableRoutes: state.auth.availableRoutes
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
