import { updateObject } from '../../shared/utility';
import * as actionTypes from '../actions/action-types';

export const initialState = {
  success: null,
  loading: false,
  errors: null,
  msg: null,
  allSO: [],
  traceabilitySoSuccess: null,
  traceabilitySoLoading: false,
  traceabilitySoErrors: null,
  traceabilitySoMsg: null,
  traceability_so: {},
  generateQrSuccess: null,
  generateQrLoading: false,
  generateQrErrors: null,
  generateQrMsg: null,
  qrCode: {},
  baleLotSuccess: null,
  baleLotLoading: false,
  baleLotErrors: null,
  baleLotMsg: null,
  baleLot: [],
  baleLotDocs: []
};

const getTraceabilitySOStart = (state, action) =>
  updateObject(state, {
    traceabilitySoSuccess: null,
    traceabilitySoLoading: true,
    traceabilitySoErrors: null,
    traceabilitySoMsg: null,
    traceability_so: {}
  });

const getTraceabilitySOSuccess = (state, action) => {
  return updateObject(state, {
    traceabilitySoSuccess: true,
    traceabilitySoLoading: false,
    traceabilitySoErrors: null,
    traceabilitySoMsg: null,
    traceability_so: action.traceability_so
  });
};

const getTraceabilitySOFail = (state, action) => {
  return updateObject(state, {
    traceabilitySoSuccess: null,
    traceabilitySoLoading: false,
    traceabilitySoErrors: true,
    traceabilitySoMsg: action.error.message
  });
};

const getAllSOStart = (state, action) =>
  updateObject(state, {
    success: null,
    loading: true,
    errors: null,
    msg: null
  });

const getAllSOSuccess = (state, action) => {
  return updateObject(state, {
    success: true,
    loading: false,
    errors: null,
    msg: null,
    allSO: action.allSO
  });
};

const getAllSOFail = (state, action) => {
  return updateObject(state, {
    success: null,
    loading: false,
    errors: true,
    msg: action.error.message,
    allSO: []
  });
};

const generateQrCodeStart = (state, action) =>
  updateObject(state, {
    generateQrSuccess: null,
    generateQrLoading: true,
    generateQrErrors: null,
    generateQrMsg: null
  });

const generateQrCodeSuccess = (state, action) => {
  let updatedAllSO = state.allSO.map(item => {
    if (item.name === action.data.soLineItem) {
      item.has_qr_code = true;
      item.qr_journey = action.data.payload;
      return item;
    } else return item;
  });
  return updateObject(state, {
    generateQrSuccess: true,
    generateQrLoading: false,
    generateQrErrors: null,
    generateQrMsg: null,
    qrCode: action.qrCode,
    allSO: updatedAllSO
  });
};

const resetGenerateQrCode = (state,action) => {
  return updateObject(state, {
    generateQrSuccess: null,
    generateQrLoading: false,
    generateQrErrors: null,
    generateQrMsg: null
  })
}

const generateQrCodeFail = (state, action) => {
  return updateObject(state, {
    generateQrSuccess: null,
    generateQrLoading: false,
    generateQrErrors: true,
    generateQrMsg: action.error.message,
    qrCode: {}
  });
};

const getBalesByNumberStart = (state, action) =>
  updateObject(state, {
    baleLotSuccess: null,
    baleLotLoading: true,
    baleLotErrors: null,
    baleLotMsg: null,
    baleLot: [],
    baleLotDocs: []
  });

const getBalesByNumberSuccess = (state, action) => {
  let finalReportArray = [];
  if(action.bales.activities.length)
  {
    action.bales.activities
    .filter(item => item.type.toLowerCase() === 'testing report')
    .map(item => {
      item.documents.map(doc => {
        switch (doc.document_type) {
          case 'CATGO':
            finalReportArray.push(doc);
            break;
          case 'ORIGIN':
            finalReportArray.push(doc);
            break;
          case 'SGS':
            finalReportArray.push(doc);
            break;
          default:
            break;
        }
        return null;
      });
      return null;
    });
  }
  
  return updateObject(state, {
    baleLotSuccess: true,
    baleLotLoading: false,
    baleLotErrors: null,
    baleLotMsg: null,
    baleLot: action.bales.upstream_batches ? action.bales.upstream_batches : [],
    baleLotDocs: finalReportArray
  });
};

const getBalesByNumberFail = (state, action) => {
  return updateObject(state, {
    baleLotSuccess: false,
    baleLotLoading: false,
    baleLotErrors: true,
    baleLotMsg: action.error.message,
    baleLot: [],
    baleLotDocs: []
  });
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TRACEABILITY_SO_START:
      return getTraceabilitySOStart(state, action);
    case actionTypes.GET_TRACEABILITY_SO_SUCCESS:
      return getTraceabilitySOSuccess(state, action);
    case actionTypes.GET_TRACEABILITY_SO_FAIL:
      return getTraceabilitySOFail(state, action);

    case actionTypes.GET_ALL_SO_START:
      return getAllSOStart(state, action);
    case actionTypes.GET_ALL_SO_SUCCESS:
      return getAllSOSuccess(state, action);
    case actionTypes.GET_ALL_SO_FAIL:
      return getAllSOFail(state, action);

    case actionTypes.GET_BALES_BY_NUMBER_START:
      return getBalesByNumberStart(state, action);
    case actionTypes.GET_BALES_BY_NUMBER_SUCCESS:
      return getBalesByNumberSuccess(state, action);
    case actionTypes.GET_BALES_BY_NUMBER_FAIL:
      return getBalesByNumberFail(state, action);

    case actionTypes.GENERATE_QR_CODE_START:
      return generateQrCodeStart(state, action);
    case actionTypes.GENERATE_QR_CODE_SUCCESS:
      return generateQrCodeSuccess(state, action);
    case actionTypes.GENERATE_QR_CODE_FAIL:
      return generateQrCodeFail(state, action);
    case actionTypes.RESET_GENERATE_QR_CODE:
      return resetGenerateQrCode(state,action)

    default:
      return state;
  }
};
