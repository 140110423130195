import React from 'react'

function ProductLine() {
    return (
        <div>
            <h1>ProductLine</h1>
        </div>
    )
}

export default ProductLine
