import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Progress, Row } from "reactstrap";
import AddDepartment from '../../shared/AddDepartment';
import { titleCaseConverter } from '../../shared/utility';
import Validators from '../../shared/Validations';
import { createUser, init } from '../../store/actions/index';
import EditUser from '../users/EditUser';
import './Users.css';
export default function Users(props) {
    const [emailProperties, setEmailProperties] = useState({
        email: '',
        emailTouched: false,
        emailOkay: false
      });
    const [userArray, setUserArray] = useState([]);
    const [addUserRenderer, setAddUserRenderer] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [firstNameProperties, setFirstNameProperties] = useState({
        firstName: '',
        firstNameTouched: false,
        firstNameOkay: false
      });
    //   const [lastNameProperties, setLastNameProperties] = useState({
    //     lastName: '',
    //     lastNameTouched: false,
    //     lastNameOkay: false
    //   });
    const [userDetails, setUserDetails] = useState([]);
    const [enableCreateUser, setEnableCreateUser] = useState(false);
    const [hideAddMore, setHideAddMore] = useState(false);
    const [disableAddMore, setDisableAddMore] = useState(false);
    const [isWorking, setIsWorking] = useState(false);
    const [updating, setUpdating] = useState(false);

    const controlName=['email','firstName']
    const validators = {
        email: {
            validators: value => Validators.email(value),
            errorMessage: 'Email is not in correct format'
        },
        firstName: {
            validators: value => Validators.fieldRequired(value),
            errorMessage: 'Name field cannot be blank or less than 3 characters '
        }
    };

 

  const allDepartments = useSelector(
    (state) => state.departments.allDepartments
  );
  const createUserLoading = useSelector((state) => state.users.loading);
  const createUserSuccess = useSelector((state) => state.users.success);
  const createUserMsg = useSelector((state) => state.users.msg);
  const createUserError = useSelector((state) => state.users.errors);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(init());
  }, [dispatch]);

    const functionTypeHandler = {
        emailProperties,
        setEmailProperties,
        firstNameProperties ,
        setFirstNameProperties
        // lastNameProperties,
        // setLastNameProperties
      };
    useEffect(() => {
        if (isWorking && updating) {
            let tempUserDepts = [];
            if (userDetails[0].chosenDepartment.name === 'All') {
                allDepartments.forEach(item => {
                    tempUserDepts.push({
                        department_id: item.department_id,
                        role: userDetails[0].chosenRole
                    })
                })
            }
            else {
                userDetails.forEach(item => {
                    tempUserDepts.push({
                        department_id: item.chosenDepartment.department_id,
                        role: item.chosenRole
                    }
                    );
                })
            }
            let payload = {
                email: emailProperties.email,
                name:firstNameProperties.firstName,
                // lastName:lastNameProperties.lastName,
                departments: tempUserDepts
            };
            dispatch(createUser(payload));
            setIsWorking(false)
        }
        else if (!createUserLoading && createUserSuccess && updating) {
            setHideAddMore(false)
            setUpdating(false)
            let tempControlName=controlName;
            tempControlName.map(item =>{
                functionTypeHandler[`set${titleCaseConverter(item)}Properties`]({
                    ...functionTypeHandler[`${item}Properties`],
                    [`${item}`]: '',
                    [`${item}Touched`]: false,
                    [`${item}Okay`]: false
                  });
                  return null
            })
            setDepartmentList([])
            setUserDetails([])
            setAddUserRenderer([])
            setUserArray([])
        }
        else if (!createUserLoading && createUserError && updating) {
            setUpdating(false)
            setEnableCreateUser(true)
        }
    }, [isWorking, updating, createUserLoading, createUserSuccess, createUserError, userDetails, emailProperties.email, firstNameProperties.firstName, dispatch, allDepartments, controlName, functionTypeHandler] );

    const inputChangeHandler = (event, controlName) => {
            functionTypeHandler[`set${titleCaseConverter(controlName)}Properties`]({
                ...functionTypeHandler[`${controlName}Properties`],
                [`${controlName}`]: event.target.value,
                [`${controlName}Touched`]: true,
                [`${controlName}Okay`]: validators[controlName].validators(event.target.value)
              });
    };
    const removeDocAddWindow = (index, name) => {
        let tempUserAddRenderer = addUserRenderer
            .slice()
            .filter((item, i) => i !== index);
        let tempUserArray = userArray
            .slice()
            .filter((item, i) => i !== index);
        let tempUserDetails = userDetails
            .slice()
            .filter((item, i) => i !== index);
        if(index + 1 === userDetails.length && index!==0 )
        {
        setDepartmentList([
            ...departmentList,
            userDetails[index-1].chosenDepartment
        ]);
        }
        else if(addUserRenderer.length <= allDepartments.length && addUserRenderer.length > 1 )
        {
            if(userDetails[index] && userDetails[index].chosenDepartment)
            {
        setDepartmentList([
            ...departmentList,
            userDetails[index].chosenDepartment
        ]);
    }
    else{
        setDepartmentList([
            ...departmentList
        ]);
    }
        }
        else {
        setDepartmentList(allDepartments);
        }

    setUserDetails(tempUserDetails)
    setAddUserRenderer(tempUserAddRenderer)
    setUserArray(tempUserArray)
    setEnableCreateUser(true)
    setDisableAddMore(true)
    setHideAddMore(false)
    //         if (index !== 0) {
    //         setDepartmentList([...departmentList, userDetails[index].chosenDepartment])
    //     }
    //     else {
    //         setDepartmentList(allDepartments)
    //     }
    };
    const handleEnableAdd = () => {
        return emailProperties.emailOkay &&
        emailProperties.emailTouched &&
           firstNameProperties.firstNameOkay &&
           firstNameProperties.firstNameTouched &&
            userArray.length
            ? userArray.reduce(
                (total, amount) => {return total && amount}, true
            )
            : false;
    };

    const selectMoreDepartment = e => {
        setUserArray([...userArray, false])
        setAddUserRenderer([...addUserRenderer, `_${addUserRenderer.length + 1}`])
        setDepartmentList(allDepartments.filter(dept => userDetails.map(item => item.chosenDepartment.name !== dept.name).reduce((acc, cur) => { return acc && cur }, true)))
        setDisableAddMore(false)
        setEnableCreateUser(false)
    };
    const handleAdd = () => {
        setUpdating(true)
        setIsWorking(true)
        setEnableCreateUser(false)
    };
    const trackChosenDepartment = (data) => {
        let tempUserDetails = userDetails;
        tempUserDetails[data.index] = data.state;
        setUserDetails(tempUserDetails)
        setDisableAddMore(tempUserDetails[data.index].enableAddMore)
        let tempUserArray=userArray.slice();
        if(tempUserDetails[data.index].chosenDepartment.name)
        {
        
            if(tempUserDetails[data.index].chosenRole!=='Choose Role')
            {
                   tempUserArray[data.index]=true
            }
            else{
                tempUserArray[data.index]=false
            }
        }
        if(tempUserDetails[data.index].chosenDepartment.name && tempUserDetails[data.index].chosenDepartment.name.toLowerCase() === 'all')
        {
            setHideAddMore(true)
        }
        else{
            setHideAddMore(false)
        }
        setUserArray(tempUserArray)
  }


  useEffect(()=>{
   setEnableCreateUser(handleEnableAdd())
},[userArray,emailProperties,firstNameProperties])
    return (
        <React.Fragment>
             {/* <Form> */}
        <Container fluid className='pb-5'>
        <Row>
            <Col lg='9' md='9' sm='12' xs='12' >
                <div className='text-left mb-2'>
              <span className='h1 mb-2 text-primary font-weight-bold text-capitalize'>
               User Management
              </span>
              </div>
            </Col>
          </Row>
            <Row>
                <Card className="shadow my-2 px-0 section-add-user w-100">
                    <CardHeader className='text-left no-border'>
                        <span className='h2'>Add User</span>
                    </CardHeader>
                    <CardBody>     
                    {/* <Row>  
                        <Col className="col-md-6 mt-2"> */}
                                <Label >Add Email Address</Label>
                                <FormGroup >
                                <InputGroup className="input-group-alternative border-style w-75">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          {/* <img
                            alt="..."
                            src={require("../../assets/img/icons/mail.svg")}
                            width="80%"
                          /> */}
                          <i className="fas fa-envelope text-primary"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                                <Input
                                    // className='border border-dark rounded p-1 mt-3'
                                    autoComplete='off'
                                    placeholder='Enter Email Address'
                                    value={emailProperties.email}
                                    onChange={event =>
                                        inputChangeHandler(event,'email')
                                    }
                                />
                                </InputGroup>
                                {emailProperties.emailTouched &&
                                    !emailProperties.emailOkay ? (
                                        <span className='text-danger'>
                                            <small>
                                                {validators['email'].errorMessage}
                                            </small>
                                        </span>
                                    ) : null}
                                                            </FormGroup>

                        {/* </Col>
                        </Row> */}
                        {/* <Row>
                            <Col className='col-md-6 mt-2'> */}
                                <Label >Add Name</Label>
                                <FormGroup>
                                <InputGroup className="input-group-alternative border-style w-75">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          {/* <img
                            alt="..."
                            src={require("../../assets/img/icons/name.png")}
                            width="100%"
                          /> */}
                          <i className="fas fa-user text-primary"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                                <Input
                                    // className='border border-dark rounded p-1 mt-2'
                                    autoComplete='off'
                                    placeholder='Enter Name'
                                    value={firstNameProperties.firstName}
                                    onChange={event =>
                                        inputChangeHandler(event, 'firstName')
                                    }
                                />
                                </InputGroup>
                                {firstNameProperties.firstNameTouched &&
                                    !firstNameProperties.firstNameOkay ? (
                                        <span className='text-danger'>
                                            <small>
                                                {validators['firstName'].errorMessage}
                                            </small>
                                        </span>
                                    ) : null}
                                 </FormGroup>
                            {/* </Col>
                            </Row> */}
                        {/* <Row> */}
                            <Label className='mt-3'>Add Multiple Departments</Label>
                        {/* </Row> */}
                        {addUserRenderer.map((group, index) => (
                            <AddDepartment
                                index={index}
                                key={index}
                                removeDocAddWindow={removeDocAddWindow}
                                selectMoreDepartment={selectMoreDepartment}
                                allDepartments={allDepartments}
                                userArray={userArray}
                                addUserRenderer={addUserRenderer}
                                departmentList={departmentList}
                                trackChosenDepartment={trackChosenDepartment}
                                handleEnableAdd={handleEnableAdd}
                                userDetails={userDetails}
                            />
                        ))}
                        {/* <Row> */}
                            <FormGroup className='mt-2'>
                                <Button
                                    className='btn-border-style-outline btn-outline-primary m-0 ml-3 mt-4'
                                    color='primary'
                                    type='button'
                                    disabled={addUserRenderer.length ? !disableAddMore : false}
                                    hidden={departmentList.length === 1 || hideAddMore  ? true : null}
                                    onClick={e => selectMoreDepartment(e)}
                                >
                                    {addUserRenderer.length ? 'Add More' : 'Assign Department'}
                                </Button>
                            </FormGroup>
                        {/* </Row> */}
                        <FormGroup>
                        { createUserError ? (
                <Alert color='warning'>
                  <strong>Warning!</strong> {createUserMsg}
                </Alert>
              ) : createUserSuccess ?
                  <Alert color="success">
              {createUserMsg}
           </Alert>:null}
           </FormGroup>
             <Row className='justify-content-center'>
             {/* <FormGroup className='justify-content-center'> */}
                <Button
                  color='primary'
                  className='btn-border-style-outline btn-primary m-0 ml-3 mt-2 pl-5 pr-5'
                  type='button'
                  hidden={isWorking || updating}
                  disabled={!enableCreateUser || updating }
                  onClick={() => handleAdd()}
                >
                  Create User
                </Button>
                {/* </FormGroup> */}
                {isWorking || updating ? (
                  <div className='w-100 p-3'>
                    <Progress animated bar value='100'>
                      <strong>
                        Please Wait while the data is uploading...
                      </strong>
                    </Progress>
                  </div>
                ) : null}
              </Row>
            </CardBody>
          </Card>
        </Row>
        <Row>
          <EditUser 
            allDepartments={allDepartments}
             />
        </Row>
        </Container>
       {/* </Form> */}
     </React.Fragment> 
  );
}
